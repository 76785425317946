import { faStar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { LIGHT_TEXT_COLOR } from '../constants/global'

const RatingsComponent = ({ rating, reviews, scale }) => {
    return <div style={{ marginLeft: scale ? -15 : 0, transform: scale ? 'scale(' + scale + ')' : undefined }} >
        {[...Array(parseInt(rating))].map(element => {
            return <FontAwesomeIcon icon={faStar} color='#F0BD18' size='sm' style={{ marginRight: 2 }} />
        })}
        <span style={{ color: 'black', fontSize: 14, fontWeight: '500', marginLeft: 5 }} >{rating} <span style={{ color: LIGHT_TEXT_COLOR, textDecoration: 'underline' }} >({reviews})</span></span>
    </div>
}

export default RatingsComponent