import { faAngleLeft, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const BackButton = ({ styles }) => {
    return <button style={{ width: 30, height: 30, backgroundColor: 'white', borderRadius: 40, alignItems: 'center', justifyContent: 'center', borderWidth: 0, boxShadow: "1px 1px 4px #ddd", ...styles }} >
        <FontAwesomeIcon icon={faChevronLeft} color='black' />
    </button>
}

export default BackButton