import { faBed, faCalendar, faCircle, faInfoCircle, faStar, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import getSymbolFromCurrency from 'currency-symbol-map';
import moment from 'moment';
import React, { useCallback, useRef, useState } from 'react';
import { LIGHT_TEXT_COLOR, THEME_COLOR, THEME_COLOR_ALPHA, THEME_COLOR_BLUE } from '../constants/global';
import { GetTotalHotelGrossPrice, GetTotalHotelRooms, GetTotalNights } from './BookingFunctions';
import './GetFlightSearchItem.css';
import RatingsItem from './RatingsItem';

const GetHotelSearchItem = React.memo(({ travellers, data, bookingSearchInfo, isHotelSearch, isSelected, selectable, onHotelChange, onChange, isCheckout, setHotelRoomData }) => {
    let optionsSheet = null;
    const hotelRatings = ["Very Bad", "Bad", "Moderate", "Good", "Excellent"];
    const [isPriceDetails, setIsPriceDetails] = useState(false);
    const priceDetailsRef = useRef();

    const onChangeCallback = useCallback(() => onChange(), [])
    const onHotelChangeCallback = useCallback(() => onHotelChange(), [])
    const [roomsVisible, setRoomsVisible] = useState([]);

    const totalNights = GetTotalNights(data)
    const totalHotelRooms = GetTotalHotelRooms(data);
    return <div className='container' >
        <div className='box-shadow' style={{ position: 'relative', marginTop: 10, marginBottom: 10, borderRadius: 20, alignSelf: 'stretch' }} >
            <div onPress={() => { if (onHotelChange) onHotelChange() }} underlayColor="#ddd" style={{ borderRadius: 20 }} >
                <div >
                    <div style={{}} >
                        <div style={{ backgroundColor: THEME_COLOR_ALPHA, paddingLeft: 15, paddingRight: 15, paddingTop: 10, paddingBottom: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10 }} >
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', }} >
                                <FontAwesomeIcon icon={faBed} color={THEME_COLOR} size="xs" />
                                <span style={{ color: '#606060', fontSize: 16, marginLeft: 10, fontWeight: 'bold' }} >{data.cityName}</span>
                                <div style={{ flex: 1 }} />
                                <span style={{ color: '#606060', fontSize: 16 }} >{moment(data.fromDate, 'MM/DD/YYYY').format('D MMM')} - {moment(data.toDate, 'MM/DD/YYYY').format('D MMM')}</span>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', height: 'auto', padding: 15 }} >
                            <div style={{ display: 'flex', flexDirection: 'row' }} >
                                <img style={{ width: 140, height: 140, alignSelf: 'stretch', marginRight: 15, borderRadius: 15 }} src={data.lodgingImage} />
                                <div style={{ height: 140, display: 'flex', flexDirection: 'column', alignSelf: 'stretch', borderBottomStyle: 'solid', borderBottomColor: '#ddd', borderBottomWidth: 1 }} >
                                    <span style={{ color: 'black', fontWeight: '500', fontSize: 16, height: 40, overflow: 'hidden', textOverflow: 'ellipsis' }} >{data.hotelName}</span>
                                    <RatingsItem rating={data.rating} googleRating={data.googleRatings} googleReviews={data.numGoogleReviews} googleReviewLink={data.googleReviewsLink} />
                                    <div style={{ flex: 1 }} />
                                    <div style={{ justifyContent: 'flex-end' }} >
                                        <div style={{ display: 'flex', flexDirection: 'row', overflow: 'hidden', alignItems: 'center', marginBottom: 6 }} >
                                            <FontAwesomeIcon icon={faCircle} color='black' size='2xs' />
                                            <span style={{ fontSize: 12, color: 'rgba(0, 0, 0, 0.7)', marginLeft: 6, }} numberOfLines={1} >{data.hotelRooms[0]?.roomType}</span>
                                        </div>
                                        {data.cashBackValue && data.cashBackValue > 0 && <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 6 }} >
                                            <FontAwesomeIcon icon={faCircle} color='black' size='2xs' />
                                            <span style={{ fontSize: 12, color: 'rgba(0, 0, 0, 0.7)', marginLeft: 6, }} numberOfLines={1} >{data.cashBack}% Cashback via PayPal</span>
                                        </div>}
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 6 }} >
                                            <FontAwesomeIcon icon={faCircle} color='black' size='2xs' />
                                            <span style={{ fontSize: 12, color: 'rgba(0, 0, 0, 0.7)', marginLeft: 6, }} numberOfLines={1} >{data.hotelRooms[0]?.cancelPolicy != null && data.hotelRooms[0]?.cancelPolicy?.length > 0 ? "Fully refundable" : "Non-Refundable"} {data.hotelRooms[0]?.cancelPolicy != null && data.hotelRooms[0]?.cancelPolicy?.length > 0 && <FontAwesomeIcon icon={faInfoCircle} size="xs" />}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} >
                                <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }} >
                                    <span style={{ color: 'black', fontSize: 10 }} >{getSymbolFromCurrency(data.currencyCode)}{parseFloat(GetTotalHotelGrossPrice(data.hotelRooms)).toLocaleString()} for {totalNights + (totalNights > 1 ? ' Nights' : ' Night')}, {totalHotelRooms + (totalHotelRooms > 1 ? ' Rooms' : ' Room')}</span>
                                    <span style={{ color: 'black', fontSize: 10 }} >Included Taxes and Fees</span>
                                </div>
                                {data.hotelRooms.map(roomData => <div style={{ borderBottomStyle: 'solid', borderBottomColor: '#ddd', borderBottomWidth: 1, paddingTop: 10, paddingBottom: 10 }} >
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', }} >
                                        <span style={{ fontSize: 18, color: THEME_COLOR, fontWeight: 'bold' }} >{getSymbolFromCurrency(roomData.currency)} {parseFloat(roomData.pricePerNight).toLocaleString()}</span>
                                        <span style={{ fontSize: 10, color: LIGHT_TEXT_COLOR }} >per night per room</span>
                                    </div>
                                </div>)}
                            </div>
                            <div style={{ alignSelf: 'stretch', display: 'flex', justifyContent: 'flex-end', marginTop: 10 }} >
                                <span style={{ color: 'black', textAlign: 'right', fontSize: 14 }} >Booking # {data.pnrCode}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {data.isBookingCancelled && <div style={{ display: 'flex', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, alignItems: 'center', justifyContent: 'center' }} pointerEvents="none" >
                <div style={{ width: 120, height: 120, borderRadius: 20, rotate: '315deg' }} >
                    <img src={require('../assets/cancel_ui_image.png')} style={{ width: '100%', height: '100%' }} />
                </div>
            </div>}
        </div>
        {/* <View style={{ marginHorizontal: 20 }} >
            {data.hotelRooms.map(item => isCheckout || roomsVisible.includes(item.roomCode) ? <HotelRoomItem
                setHotelRoomData={setHotelRoomData}
                isCheckout={isCheckout}
                totalNights={totalNights}
                data={item}
            /> : <View />)}
        </View> */}
        {data.hotelRooms[0].guestDetails != null && <div className='box-shadow' >
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 20, marginTop: 10, padding: 10 }} >
                <div style={{ marginRight: 20, flex: 1, display: 'flex', flexDirection: 'column' }} >
                    <span style={{ fontWeight: 'bold', marginBottom: 6 }}>Guest details</span >
                    {[...Array(data.hotelRooms[0].numberOfRooms ? data.hotelRooms[0].numberOfRooms : 1)].map((element, index) => <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 5, marginBottom: 5 }} >
                        <span style={{ fontWeight: 'bold' }} >Room {index + 1}</span>
                        <FontAwesomeIcon icon={faUserCircle} size={24} style={{ marginLeft: 10, marginRight: 10 }} />
                        <div style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 8, paddingBottom: 8, backgroundColor: '#ddd', borderRadius: 40, flex: 1 }} ><span style={{ fontWeight: 'bold', color: 'black', }} >{data.hotelRooms[0].guestDetails && data.hotelRooms[0].guestDetails != null && data.hotelRooms[0].guestDetails[index] && data.hotelRooms[0].guestDetails[index].roomOwner ? data.hotelRooms[0].guestDetails[index].roomOwner : ''}</span></div>
                    </div>)}
                </div>
            </div>
        </div>}
    </div>
})

export default GetHotelSearchItem;