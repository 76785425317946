import React from 'react'
import { LIGHT_TEXT_COLOR } from '../constants/global'
import RatingsComponent from './RatingsComponent'

const ReviewItem = ({ item }) => {
    return <div style={{ display: 'inline-block', width: 300, height: 200, backgroundColor: 'white', borderRadius: 10, marginRight: 15, padding: 15 }} >
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }} >
            <div style={{ display: 'flex', alignItems: 'center' }} >
                <img src={item.userPic} style={{ width: 45, height: 45, borderRadius: 40, objectFit: 'cover' }} />
                <div style={{ marginLeft: 10 }} >
                    <span style={{ display: 'block', fontWeight: 600 }} >{item.userName}</span>
                    <span style={{ display: 'block', marginTop: 5, color: LIGHT_TEXT_COLOR, fontSize: 12 }} >{item.time}</span>
                </div>
            </div>
            <div style={{ flex: 1, display: 'block', whiteSpace: 'pre-wrap', marginTop: 10 }} >
                <span style={{ color: LIGHT_TEXT_COLOR, fontSize: 14, }} >{item.review}</span>
            </div>
            <RatingsComponent rating={5} reviews={2} />
        </div>
    </div>
}

export default ReviewItem