import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { LIGHT_TEXT_COLOR } from '../constants/global'

const SelectionBox = ({ icon, text, onPress, rightIcon }) => {
    return <div style={{ flex: 1, display: 'flex', alignItems: 'center', borderWidth: 1, borderStyle: 'solid', borderColor: '#ddd', padding: 15, borderRadius: 10 }} >
        <FontAwesomeIcon icon={icon} color={LIGHT_TEXT_COLOR} style={{ width: 30 }} />
        <span style={{ color: LIGHT_TEXT_COLOR, fontSize: 16, flex: 1 }} >{text}</span>
        <FontAwesomeIcon icon={rightIcon} color={LIGHT_TEXT_COLOR} style={{ width: 30 }} />
    </div>
}

export default SelectionBox