import { faArchive, faClock, faPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { TIME_CONST, LIGHT_TEXT_COLOR, THEME_COLOR_ALPHA, THEME_COLOR_RED, THEME_COLOR_RED_ALPHA } from '../constants/global';

const GetFlightInfoItem = ({ data }) => {
    return <div >
        {data.layover && data.layover != null && <span style={{ fontWeight: 'bold', fontSize: 12, color: THEME_COLOR_RED, paddingTop: 4, paddingBottom: 4, paddingLeft: 10, paddingRight: 10, alignItems: 'center', backgroundColor: THEME_COLOR_RED_ALPHA, borderWidth: 1, borderColor: THEME_COLOR_RED, borderStyle: 'solid', borderRadius: 5, marginTop: 15, marginBottom: 20 }} >
            Layover  {data.layover}
        </span>}
        <div style={{ borderBottomWidth: 0, borderBottomColor: '#ddd', paddingTop: 10, paddingBottom: 10, position: 'relative' }} >
            <div style={{ position: 'absolute', width: 3, backgroundColor: '#ddd', top: 35, bottom: 35, left: 13 }} />
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 2, marginBottom: 2 }} >
                <span style={{ width: 65, fontWeight: 'bold' }} >{data.fromAirport}</span>
                {data.fromAirportName != null ? <span style={{ flex: 1, fontSize: 14 }} numberOfLines={1} >{data.fromAirportName}</span> : <span style={{ fontSize: 14 }} >{data.fromAirport}</span>}
            </div>
            <span style={{ fontWeight: 'bold', marginLeft: 65, marginTop: 2, marginBottom: 2, fontSize: 14 }} >{moment(data.travelStartAt, TIME_CONST).format('hh:mm A')} - {moment(data.travelEndAt, TIME_CONST).format('hh:mm A')}</span>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 65, marginTop: 2, marginBottom: 2 }} >
                <div style={{ width: 20 }} >
                    <FontAwesomeIcon icon={faClock} solid color={LIGHT_TEXT_COLOR} size="xs" />
                </div>
                <span style={{ fontWeight: '200', fontSize: 14 }} >{data.duration}</span>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 65, marginTop: 2, marginBottom: 2 }} >
                <div style={{ width: 20 }} >
                    <FontAwesomeIcon icon={faPlane} solid color={LIGHT_TEXT_COLOR} size="xs" />
                </div>
                <span style={{ fontWeight: '200', fontSize: 14 }} >{data.airlineFullName} ({data.name})</span>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 65, marginTop: 2, marginBottom: 2 }} >
                <div style={{ width: 20 }} >
                    <FontAwesomeIcon icon={faArchive} solid color={LIGHT_TEXT_COLOR} size="xs" />
                </div>
                <span style={{ fontWeight: '200', fontSize: 14 }} >{data.cabinDescrip}</span>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 2, marginBottom: 2 }} >
                <span style={{ width: 65, fontWeight: 'bold' }} >{data.toAirport}</span>
                {data.toAirportName != null ? <span style={{ flex: 1, fontSize: 14 }} numberOfLines={1} >{data.toAirportName}</span> : <span >{data.toAirport}</span>}
            </div>
        </div>
    </div>
}

export default GetFlightInfoItem;