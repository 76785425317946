const PaymentItem = ({ paymentInfo }) => {
    return <div className="box-shadow" style={{ alignSelf: 'stretch', padding: 10, paddingTop: 20, paddingBottom: 20, flexDirection: 'column', display: 'flex' }} >
        <span style={{ fontWeight: 'bold', color: 'black' }} >Payment Details</span>
        <div style={{ flexDirection: 'row', display: 'flex', fontSize: 20, alignItems: 'center', textAlign: 'justify', justifyContent: 'space-between', alignSelf: 'stretch', marginTop: 10, marginBottom: 10 }} >
            <span style={{ flex: 1, textAlign: 'center' }} >x x x x</span>
            <span style={{ flex: 1, textAlign: 'center' }}>x x x x</span>
            <span style={{ flex: 1, textAlign: 'center' }}>x x x x</span>
            <span style={{ flex: 1, textAlign: 'center' }}>{paymentInfo.CreditCardLast4}</span>
        </div>
        <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'center' }}><span style={{ color: 'grey' }} >Valid Thru:</span><span style={{ fontWeight: 'bold', marginLeft: 10, color: '#5a5a5a' }} >MM/YY</span></div>
        <span style={{ marginTop: 10, fontWeight: 'bold', textAlign: 'center' }} >{paymentInfo.CardHolderName}</span>
    </div>
}

export default PaymentItem;