import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import FlightView from "./FlightView";
import Home from "./Home";
import HotelView from "./HotelView";
import NoMatch from "./NoMatch";
import PrintTicket from "./PrintTicket";
import PrivacyPolicy from "./screens/PrivacyPolicy/PrivacyPolicy";
import TermsConditions from "./screens/TermsConditions/TermsConditions";
import AttractionDetails from "./screens/AttractionScreens/AttractionDetails";

function App() {
  return (
    <BrowserRouter >
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/PrintTicket" element={<PrintTicket />} />
        <Route path="/HotelView" element={<HotelView />} />
        <Route path="/FlightView" element={<FlightView />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/TermsConditions" element={<TermsConditions />} />
        <Route path="/AttractionDetails" element={<AttractionDetails />} />
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
