import React, { useRef, useState } from 'react'
import BackButton from '../../components/BackButton'
import { LIGHT_TEXT_COLOR, THEME_COLOR } from '../../constants/global'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCalendar, faCheck, faChevronDown, faLocation, faMapMarker, faMapMarkerAlt, faPeopleGroup, faPerson, faStar, faTimes } from '@fortawesome/free-solid-svg-icons'
import LoadingView from '../../components/LoadingView'
import AppAssets from '../../assets'
import CollapsableContainer from '../../components/CollapsableContainer'
import SelectionBox from '../../components/SelectionBox'
import { faCalendarAlt, faClock } from '@fortawesome/free-regular-svg-icons'
import AttractionCard from './components/AttractionCard'
import RatingsComponent from '../../components/RatingsComponent'
import ReviewItem from '../../components/ReviewItem'

const textColor = '#242F40'
const lightTextColor = '#7A818C'

const attractionDetails = {
    images: [
        "https://cdn.britannica.com/95/166395-050-37165D6F/Roller-coaster-Six-Flags-Magic-Mountain-Valencia.jpg"
    ],
    title: 'Lago Di Como- Weekend',
    activityDuration: '1 hour 20 mins',
    provider: 'gambi tour',
    rating: 4.6,
    reviews: 232,
    reviewsLinks: '',
    emenities: [
        { icon: "doc_cancel_icon", name: 'Cancellation policy' },
        { icon: "clock_icon", name: 'Duration 2 hours' },
        { icon: "tour_guide_icon", name: 'Live tour guide' },
        { icon: "wheel_chair_icon", name: 'Wheelchair accessible' },
    ],
    highlights: [
        "Guided visit of the Eiffel Tower",
        "Enjoy breathtaking panoramic views of Paris",
        "Learn more about the history of the Eiffel Tower"
    ],
    description: "A small commune in the province of Como, Sorico is far from the glamorous perception that’s associated with Lake Como. But, it’s for this very reason why we chose it. Located towards the north end of Lake Como, Sorico has a more local feel. Saying that, there are still signs of tourism around, with restaurants, bars and campsites in the area.",
    inc: {
        includes: [
            "Access to the 2nd floor by elevator",
            "Access to the summit by elevator (if option selected)",
            "Guided visit",
            "Unlimited time inside the Eiffel Tower"
        ],
        not_includes: [
            "Hotel pickup and drop-off",
            "Food and drinks",
            "Gratuities"
        ]
    },
    meeting_point: {
        address: "Please meet your guide at the travel agency Paris Lounge 38 avenue de la Bourdonnais, 75007 Paris. You can reach the meeting point by taking the Metro line 6, 8, and RER C. Once you arrive, please exchange your voucher for a ticket. Do not go directly to the Eiffel Tower.",
        google_map_link: ''
    },
    important_information: {
        "Not Allowed": [
            "Weapons or sharp objects",
            "Glass objects"
        ],
        "Know before you go": [
            "You may have to wait in lines for security and for the elevators",
            "In high season, the total wait to access the 2nd floor can be up to 25 minutes",
            "Summit ticket holders will have to wait in line on the 2nd floor to access the summit's elevators. In high season, this wait can be up to an additional 20 minutes"
        ]
    },
    actualPrice: 40.00,
    totalPrice: 54.00,
    currency: 'USD',
    related: [
        {
            name: 'Pigeon island',
            duration: '1.2 hours',
            rating: 4.6,
            reviews: 232,
            reviewsLink: '',
            actualPrice: 40.00,
            totalPrice: 54.00,
            currency: 'USD',
            image: 'https://karnatakatourism.org/wp-content/uploads/2020/06/WOnderla-Portrait.jpg',
            time: '08/13/2024 13:00'
        },
        {
            name: 'Asmara Beach',
            duration: '2 hours',
            rating: 4.6,
            reviews: 232,
            reviewsLink: '',
            actualPrice: 120.00,
            totalPrice: 140.00,
            currency: 'USD',
            image: 'https://image-cdn.hypb.st/https%3A%2F%2Fhypebeast.com%2Fimage%2F2023%2F07%2Froyal-caribbean-international-icon-of-the-seas-cruise-1.jpg?cbr=1&q=90',
            time: null
        }
    ],
    reviewsList: [
        {
            userName: 'Brandon Vetrovs',
            time: '03/24/2024',
            review: 'Our tour guide Samy was wonderful. He was a nice guy but it felt more like he was our escort rather than a tour guide. We wished there was more history and knowledge shared during the tour.',
            rating: 5,
            userPic: 'https://img.freepik.com/free-photo/portrait-man-laughing_23-2148859448.jpg?size=338&ext=jpg&ga=GA1.1.1141335507.1718668800&semt=ais_user'
        },
        {
            userName: 'Alfredo Aminoff',
            time: '03/24/2024',
            review: 'The Eiffel Tower is great! Had (after Get Your Guide not forwarded our first booking to the subcontractor despite payment) the 2nd time (now booked for a significantly more expensiv',
            rating: 5,
            userPic: 'https://img.freepik.com/free-photo/freedom-concept-with-hiker-mountain_23-2148107064.jpg?size=626&ext=jpg&ga=GA1.1.1141335507.1718841600&semt=ais_user'
        }
    ]
}

const AttractionDetails = () => {
    const [isLoading, setIsLoading] = useState(false)
    const availabilityRef = useRef()

    return isLoading ? <LoadingView /> : <div style={{}} >
        <div style={{}}>
            <BackButton styles={{ position: 'absolute', top: 20, left: 20, zIndex: 10 }} />
            <div style={{ height: 340, position: 'relative' }} >
                <img style={{ position: 'absolute', height: '100%', zIndex: -10, maxWidth: '100%', objectFit: 'cover' }} src={attractionDetails.images[0]} />
            </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', padding: 15, backgroundColor: '#F5F5F5', borderTopLeftRadius: 20, borderTopRightRadius: 20, marginTop: -30 }} >
            <div style={{ flex: 1, display: 'flex', alignItems: 'center' }} >
                <span style={{ fontWeight: 'bold', flex: 1 }} >{attractionDetails.title}</span>
                <span style={{ color: lightTextColor, fontSize: 14, fontWeight: '500' }} >{attractionDetails.activityDuration}</span>
            </div>
            <span style={{ color: lightTextColor, fontSize: 14, fontWeight: '500', marginTop: 10, marginBottom: 10 }} >Activity Provider: <span style={{ color: THEME_COLOR, textDecoration: 'underline' }} >{attractionDetails.provider}</span></span>
            <RatingsComponent rating={attractionDetails.rating} reviews={attractionDetails.reviews} />
            <h4>About This Activity</h4>
            <div style={{ flex: 1, flexDirection: 'row', display: 'flex', gap: 8 }} >
                {attractionDetails.emenities.map(element => <AmenityItem item={element} />)}
            </div>
            <h4>Experience</h4>
            <CollapsableContainer title="Hightlights" >
                <ul style={{ margin: 0, padding: 0, marginLeft: 25, marginTop: 0 }} >
                    {attractionDetails.highlights.map(element => <li style={{ color: lightTextColor, fontSize: 14 }} >{element}</li>)}
                </ul>
            </CollapsableContainer>
            <CollapsableContainer title="Description" >
                <span style={{ color: lightTextColor, fontSize: 14 }} >{attractionDetails.description}</span>
            </CollapsableContainer>
            <CollapsableContainer title="Includes" >
                {attractionDetails.inc.includes.map(element => <span style={{ display: 'block', color: lightTextColor, fontSize: 14, marginBottom: 2 }} ><FontAwesomeIcon icon={faCheck} color='green' size='sm' style={{ width: 15 }} /> {element}</span>)}
                {attractionDetails.inc.not_includes.map(element => <span style={{ display: 'block', color: lightTextColor, fontSize: 14, marginBottom: 2 }} ><FontAwesomeIcon icon={faTimes} color='red' size='sm' style={{ width: 15 }} /> {element}</span>)}
            </CollapsableContainer>
            <CollapsableContainer title="Meeting Point" >
                <span style={{ color: lightTextColor, fontSize: 14 }} >{attractionDetails.meeting_point.address}</span>
                <span style={{ color: THEME_COLOR, fontSize: 14, display: 'block', marginTop: 10, fontWeight: '600', textDecoration: 'underline' }} >Open in google map <FontAwesomeIcon icon={faArrowRight} /></span>
            </CollapsableContainer>
            <CollapsableContainer title="Hightlights" >
                {Object.keys(attractionDetails.important_information).map(element => <div >
                    <span style={{ fontSize: 12, fontWeight: 'bold', color: textColor }} >{element}</span>
                    <ul style={{ margin: 0, padding: 0, marginLeft: 25, marginTop: 5, marginBottom: 10 }} >
                        {attractionDetails.important_information[element].map(element => <li style={{ color: lightTextColor, fontSize: 14 }} >{element}</li>)}
                    </ul>
                </div>)}
            </CollapsableContainer>
            <h4 ref={availabilityRef} >Check Availability</h4>
            <AvailabilityItem availabilityRef={availabilityRef} />
            <div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }} >
                <h4 style={{ flex: 1 }} >You Might Also Like...</h4>
                <span style={{ color: THEME_COLOR, fontSize: 14, fontWeight: 'bold' }} >View All</span>
            </div>
            {attractionDetails.related.length > 0 && <div style={{ whiteSpace: 'nowrap', overflowX: 'scroll', marginLeft: -15, marginRight: -15, paddingLeft: 15, paddingRight: 15 }} >
                {attractionDetails.related.map(element => <AttractionCard item={element} />)}
                {attractionDetails.related.map(element => <AttractionCard item={element} />)}
                {attractionDetails.related.map(element => <AttractionCard item={element} />)}
            </div>}
            <div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }} >
                <h4 style={{ flex: 1 }} >Reviews</h4>
                <span style={{ color: THEME_COLOR, fontSize: 14, fontWeight: 'bold' }} >View All</span>
            </div>
            {attractionDetails.reviewsList.length > 0 && <div style={{ whiteSpace: 'nowrap', overflowX: 'scroll', marginLeft: -15, marginRight: -15, paddingLeft: 15, paddingRight: 15 }} >
                {attractionDetails.reviewsList.map(element => <ReviewItem item={element} />)}
            </div>}
            <div style={{ height: 100 }} />
        </div>
    </div>
}

const AvailabilityItem = ({ availabilityRef }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [availabitliyData, setAvailabitliyData] = useState(null)

    const SelectionItems = () => {
        return <div style={{ display: 'flex', flex: 1 }} >
            <SelectionBox icon={faCalendarAlt} text={"Select date"} />
            <div style={{ width: 15 }} />
            <SelectionBox icon={faPeopleGroup} text={"Passenger"} rightIcon={faChevronDown} />
        </div>
    }

    return isLoading ? <div style={{ backgroundColor: 'white', borderRadius: 10, padding: 15 }} >
        <div style={{ width: 300, height: 15, backgroundColor: '#ddd' }} />
        <div style={{ width: 200, height: 15, backgroundColor: '#ddd', marginTop: 10 }} />
        <div style={{ width: '100%', height: 1, backgroundColor: '#ddd', marginTop: 10 }} />
        <div style={{ width: 200, height: 15, backgroundColor: '#ddd', marginTop: 10 }} />
        <div style={{ width: 300, height: 15, backgroundColor: '#ddd', marginTop: 10 }} />
        <div style={{ width: 100, height: 100, backgroundColor: '#ddd', marginTop: 10, marginRight: 10, borderRadius: 4, display: 'inline-block' }} />
        <div style={{ width: 100, height: 100, backgroundColor: '#ddd', marginTop: 10, borderRadius: 4, display: 'inline-block' }} />
        <div style={{ width: 200, height: 15, backgroundColor: '#ddd', marginTop: 10 }} />
        <div style={{ width: 100, height: 100, backgroundColor: '#ddd', marginTop: 10, marginRight: 10, borderRadius: 4, display: 'inline-block' }} />
        <div style={{ width: 100, height: 100, backgroundColor: '#ddd', marginTop: 10, borderRadius: 4, display: 'inline-block' }} />
        <div />
        <div style={{ width: 100, height: 40, backgroundColor: '#ddd', marginTop: 10, marginRight: 10, borderRadius: 4, display: 'inline-block' }} />
        <div style={{ width: 100, height: 40, backgroundColor: '#ddd', marginTop: 10, borderRadius: 4, display: 'inline-block' }} />
        <div style={{ width: '100%', height: 1, backgroundColor: '#ddd', marginTop: 10 }} />
        <div style={{ width: 200, height: 15, backgroundColor: '#ddd', marginTop: 10 }} />
        <div style={{ width: 300, height: 15, backgroundColor: '#ddd', marginTop: 10 }} />
    </div> : availabitliyData != null ? <><div style={{ backgroundColor: 'white', borderRadius: 10, padding: 15 }} >
        <SelectionItems />
        <div style={{ width: '100%', height: 1, backgroundColor: '#ddd', marginTop: 10 }} />
        <div style={{ fontSize: 16, marginTop: 10, fontWeight: '500' }}>{availabitliyData.name}</div>
        <div style={{ fontSize: 14, marginTop: 10, fontWeight: '600' }} ><FontAwesomeIcon icon={faClock} color={textColor} style={{ width: 20 }} /> {availabitliyData.startTime}</div>
        <div style={{ fontSize: 14, marginTop: 10, fontWeight: '600' }} ><FontAwesomeIcon icon={faPerson} color={textColor} style={{ width: 20 }} /> Guide:{availabitliyData.guideLanguage}</div>
        <div style={{ fontSize: 14, marginTop: 10, color: THEME_COLOR, textDecoration: 'underline', fontWeight: '600' }} ><FontAwesomeIcon icon={faMapMarkerAlt} color={textColor} style={{ width: 20 }} /> {availabitliyData.address}</div>
        <h5>Select a Starting Time</h5>
        <div style={{ marginTop: -10 }} >
            {availabitliyData.slotTimes.map(element => <button onClick={() => {
                availabitliyData.selectedTime = element;
                setAvailabitliyData({ ...availabitliyData })
            }} style={{ height: 40, width: 80, borderRadius: 10, borderWidth: 1, borderStyle: 'solid', borderColor: availabitliyData.selectedTime == element ? THEME_COLOR : '#ddd', backgroundColor: availabitliyData.selectedTime == element ? THEME_COLOR : '#fff', color: availabitliyData.selectedTime == element ? 'white' : textColor, fontWeight: 'bold', marginRight: 15 }} >{element}</button>)}
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginTop: 20 }} >
            <span style={{ flex: 1, color: lightTextColor, fontSize: 14, fontWeight: 600 }} >Adult 1 x $ {availabitliyData.pricePerPerson}</span>
            <span style={{ color: lightTextColor, fontSize: 14, fontWeight: 600 }} >$ {availabitliyData.totalPrice}</span>
        </div>
        {availabitliyData.notes.map(element => <div style={{ fontSize: 14, marginTop: 10, fontWeight: '600' }} ><FontAwesomeIcon icon={faClock} color={textColor} style={{ width: 20 }} /> {element}</div>)}
        <div style={{ width: '100%', height: 1, backgroundColor: '#ddd', marginTop: 10, }} />
    </div>
        <div style={{ padding: 15, borderRadius: 10, position: 'sticky', bottom: 0, backgroundColor: 'white' }} >
            <div style={{ fontSize: 14, color: lightTextColor, marginTop: 10 }} >Total Price</div>
            <div style={{ fontSize: 24, color: THEME_COLOR, marginTop: 5, fontWeight: 'bold' }} >$ {availabitliyData.totalPrice}</div>
            <div style={{ fontSize: 14, color: lightTextColor, marginTop: 5 }} >All taxes and fees included</div>
            <button style={{ width: '100%', backgroundColor: THEME_COLOR, borderWidth: 0, borderRadius: 40, height: 40, color: 'white', fontWeight: 'bold', marginTop: 15 }} >Add to Trip</button>
        </div>
    </> : <div style={{ position: 'sticky', bottom: 0, backgroundColor: 'white', borderRadius: 10, padding: 15 }} >
        <SelectionItems />
        <button onClick={() => {
            availabilityRef.current.scrollIntoView({ behavior: 'smooth' })
            setIsLoading(true)
            setTimeout(() => {
                setIsLoading(false)
                setAvailabitliyData({
                    name: 'La Fortuna : Arenal Volcano, La Fortuna Waterfall, Hot Springs Full Day',
                    startTime: '12:00 am',
                    guideLanguage: 'English',
                    address: 'Arenal Volcano, Alajuela Province, La Fortuna, Costa Rica',
                    slotTimes: ['6:30 AM', '12:00 PM'],
                    pricePerPerson: 40,
                    currency: 'USD',
                    notes: ['Cancel before 8:00 am on june 30 for full refund', 'you can reserve now & pay later with this activity option.'],
                    totalPrice: 40,
                    selectedTime: '6:30 AM'
                })
            }, 4000)
        }} style={{ backgroundColor: THEME_COLOR, height: 40, borderRadius: 30, borderWidth: 0, color: 'white', fontWeight: 'bold', width: '100%', marginTop: 15, fontSize: 16 }} >
            Check Availability
        </button>
    </div>
}

const AmenityItem = ({ item }) => {
    return <div style={{ padding: 10, flex: 1, display: 'flex', flexDirection: 'column', aspectRatio: 1, backgroundColor: 'white', borderRadius: 10, alignItems: 'center', justifyContent: 'center' }} >
        <div style={{ flex: 2, alignItems: 'center', display: 'flex' }} >
            <img src={AppAssets.icons[item.icon]} style={{ height: 35, width: 35 }} />
        </div>
        <div style={{ flex: 1, alignItems: 'center', display: 'flex' }} >
            <span style={{ textAlign: 'center', flex: 1, fontSize: 14, color: textColor, fontWeight: '400' }} >{item.name}</span>
        </div>
    </div>
}

export default AttractionDetails