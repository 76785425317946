import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'

const CollapsableContainer = ({ title, children }) => {
    const [isExpanded, setIsExpanded] = useState(true)

    return <div style={{ display: 'flex', flex: 1, backgroundColor: 'white', borderRadius: 10, flexDirection: 'column', marginBottom: 15 }} >
        <div onClick={() => setIsExpanded(!isExpanded)} style={{ display: 'flex', flex: 1, padding: 15, alignItems: 'center' }}  >
            <span style={{ flex: 1, fontSize: 14, fontWeight: '600' }} >{title}</span>
            <FontAwesomeIcon icon={isExpanded ? faChevronUp : faChevronDown} />
        </div>
        {isExpanded && <div style={{ padding: 15, marginTop: -10 }} >
            {children}
        </div>}
    </div>
}

export default CollapsableContainer