import { faCheck, faDollarSign, faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const GetFlightBaggageInfoItem = ({ data, toAirportCity }) => {
    return <div style={{}} >
        <span style={{ fontWeight: 'bold', flexDirection: 'column', display: 'flex' }} >Flight to {toAirportCity}</span>
        <div style={{ marginTop: 10, marginBottom: 10, }} ><span style={{ fontSize: 14 }} >Cabin: {data.fareFamilyName}</span></div>
        {data.included.map(element => <BaggageInfoItem item={element} type="included" />)}
        <div style={{ height: 10 }} />
        {data.notOffered.map(element => <BaggageInfoItem item={element} type="notOffered" />)}
        <div style={{ height: 10 }} />
        {data.charged.map(element => <BaggageInfoItem item={element} type="charged" />)}
    </div>
}

const BaggageInfoItem = ({ item, type }) => {
    return <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', marginTop: 2, marginBottom: 2 }} >
        <div style={{ width: 20, alignItems: 'center' }} >
            <FontAwesomeIcon icon={GetBaggageItemIcon(type)} size="xs" />
        </div>
        <span style={{ marginLeft: 5, flex: 1, fontSize: 12 }} >{item}</span>
    </div>
}

const GetBaggageItemIcon = (type) => {
    switch (type) {
        case "included": return faCheck;
        case "notOffered": return faTimes;
        case "charged": return faDollarSign;
        default: return "circle"
    }
}

export default GetFlightBaggageInfoItem;