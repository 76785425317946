import React from 'react'
import { LIGHT_TEXT_COLOR, THEME_COLOR } from '../../../constants/global';
import RatingsItem from '../../../components/RatingsItem';
import RatingsComponent from '../../../components/RatingsComponent';

const AttractionCard = ({ item }) => {
    return <div style={{ width: 140, display: 'inline-block', marginRight: 15 }}>
        <img src={item.image} style={{ boxShadow: "1px 2px 4px #ddd", objectFit: 'cover', width: '100%', aspectRatio: 1, borderRadius: 10 }} />
        <div style={{ paddingLeft: 3, paddingRight: 3 }} >
            <span style={{ fontWeight: 600, fontSize: 14, display: 'block', marginTop: 5 }} >{item.name}</span>
            <span style={{ fontWeight: 500, fontSize: 12, display: 'block', color: LIGHT_TEXT_COLOR, marginTop: 5 }} >{item.duration}</span>
            <RatingsComponent rating={item.rating} reviews={item.reviews} scale={0.8} />
            <span style={{ display: 'block', color: LIGHT_TEXT_COLOR, fontSize: 12, marginTop: 2 }} >From <span style={{ color: THEME_COLOR, fontSize: 16, fontWeight: 'bold' }} >$ {item.actualPrice}</span> per person</span>
        </div>
    </div >
}

export default AttractionCard;