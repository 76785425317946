import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import GetFlightSearchItem from "./components/GetFlightSearchItem";
import GetHotelSearchItem from "./components/GetHotelSearchItem";
import LoadingView from "./components/LoadingView";
import travogramLogo from './assets/travogram_logo.png'
import { THEME_COLOR, LIGHT_TEXT_COLOR, THEME_COLOR_BLUE } from "./constants/global";
import './components/GetFlightSearchItem.css'
import './index.css'
import { GetTotalTravellers } from "./components/BookingFunctions";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import ReactDOMServer from 'react-dom/server'
import { saveAs } from "file-saver";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import PaymentItem from "./components/PaymentItem";
import GetTravellersInfo from "./components/GetTravellersInfo";
import SupportComponent from "./components/SupportComponent";

const PrintTicket = () => {
    const [bookingsData, setBookingsData] = useState(null)

    document.sendMessage = (data) => {
        setBookingsData({ ...data })
    };

    document.printDocument = () => {
        PrintDocument();
    }

    const onMessageReceivedFromIframe = event => {
        const eventData = event.data;
        if (eventData.type == "TravogramWebMessage") {
            const data = eventData.data;
            setBookingsData({ ...data })
        }
    }
    useEffect(() => { window.addEventListener("message", onMessageReceivedFromIframe); return () => window.removeEventListener("message", onMessageReceivedFromIframe); }, []);

    return <div style={{}} >
        <Helmet >
            <title>Print Ticket</title>
        </Helmet>
        {bookingsData != null ? <div >
            <div id="divToPrint" >
                <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 20, marginRight: 20, alignItems: 'center' }} >
                    <img src={travogramLogo} style={{ height: 26, backgroundSize: 'cover' }} />
                    <span style={{ color: THEME_COLOR, fontSize: 26, fontWeight: 'bold', marginLeft: 10, fontFamily: 'Balooda2-Bold' }} >Travogram</span>
                    <div style={{ flex: 1 }} />
                    <span style={{ marginLeft: 20, color: LIGHT_TEXT_COLOR }} >Booking ID #{bookingsData.BookingId}</span>
                </div>
                {bookingsData.FlightsData.map(item => <GetFlightSearchItem data={item} travellers={bookingsData.Travellers} travellerInfo={bookingsData.TravellerInfo} />)}
                {bookingsData.FlightsData.length > 0 && <GetTravellersInfo travellerInfo={bookingsData.TravellerInfo} />}
                {bookingsData.HotelsData.map(item => <GetHotelSearchItem data={item} travellers={bookingsData.Travellers} />)}
                <div style={{ marginLeft: 10, marginRight: 10 }} >{bookingsData.Payment.map(element => <PaymentItem paymentInfo={element} />)}</div>
                <div className='container box-shadow' style={{ marginTop: 20 }} >
                    <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 20, marginTop: 20, padding: 10, borderBottomColor: '#ddd', borderBottomWidth: 1 }} >
                        <div style={{ flex: 1, flexDirection: 'column', display: 'flex' }} >
                            <span style={{ fontSize: 25, fontWeight: '800' }} >Total Price</span>
                            <span style={{ fontSize: 10, color: LIGHT_TEXT_COLOR, fontWeight: 'bold', flex: 1, marginRight: 10 }} >{GetFlightAndHotelsPriceTagText(GetTotalTravellers(bookingsData.Travellers), bookingsData)}</span>
                        </div>
                        <div >
                            {/* <Text style={{ fontSize: 14, color: LIGHT_TEXT_COLOR, fontWeight: 'bold', textDecorationLine: 'line-through' }} >{getSymbolFromCurrency(this.state.bookingSearchInfo.currency)} {parseFloat(this.state.bookingSearchInfo.actualCost).toLocaleString()}</Text> */}
                            <span style={{ fontSize: 25, fontWeight: '800', color: THEME_COLOR }} >$ {GetTotalPrice(bookingsData).toFixed(2)}</span>
                        </div>
                    </div>
                    <div style={{ padding: 10 }} >
                        <span style={{ fontSize: 12, fontWeight: 'bold', color: LIGHT_TEXT_COLOR }} >Prices in USD. Prices includes all <span style={{ color: THEME_COLOR_BLUE }} >taxes and fees</span> charged by Travogram Inc.</span>
                    </div>
                </div>
                <SupportComponent />
                <div style={{ height: 100 }} />
            </div>
            {/* <div style={{ display: 'flex', flex: 1, justifyContent: 'center', marginBottom: 20 }} >
                <button onClick={() => PrintDocument()} style={{ alignSelf: 'center' }} >Print Receipt</button>
        </div> */}
        </div> : <LoadingView />}
    </div>
}

const GetTotalPrice = (bookingsData) => {
    let totalPrice = 0;
    bookingsData.FlightsData.forEach(element => totalPrice = totalPrice + element.totalPrice)
    bookingsData.HotelsData.forEach(element => element.hotelRooms.forEach(hotelElement => totalPrice = totalPrice + hotelElement.totalPrice))
    return totalPrice
}

const PrintDocument = () => {
    (function () {
        var originalPrintFn = window.print; // Keep it cached in this variable, just in case
        window.print = function () {
            // Trigger location change
            window.location = "com.travogram.dev:print";
        }
        window.print();
    })();
    return;
    const input = document.getElementById('divToPrint');
    input.toBlob(res => {

    })
    return;
    html2canvas(input).then((canvas) => {
        let fileWidth = 210;
        var pageHeight = 295;
        let fileHeight = (canvas.height * fileWidth) / canvas.width;
        var heightLeft = fileHeight;
        const FILEURI = canvas.toDataURL('image/png');
        // alert(FILEURI)
        // window.open(FILEURI)
        canvas.toBlob(res => saveAs(res, "Travogram Ticket.png"))


        // let PDF = new jsPDF('p', 'mm', 'a4');
        // let position = 0;
        // PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight + 10);
        // heightLeft -= pageHeight;
        // while (heightLeft >= 0) {
        //     position = heightLeft - fileHeight;
        //     PDF.addPage();
        //     PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight + 10);
        //     heightLeft -= pageHeight;
        // }
        // PDF.save(`Travogram Booking.pdf`)
    });
}


const GetFlightAndHotelsPriceTagText = (travellers, bookingSearchInfo) => {
    let tagText = "";
    const numberOfPersons = travellers;
    if (bookingSearchInfo.FlightsData != null && bookingSearchInfo.FlightsData.length > 0 && bookingSearchInfo.HotelsData != null && bookingSearchInfo.HotelsData.length > 0) {
        tagText = numberOfPersons + " " + (numberOfPersons > 1 ? "Persons" : "Person") + " Including Flights and Hotels"
    } else if (bookingSearchInfo.FlightsData != null && bookingSearchInfo.FlightsData.length > 0) {
        tagText = "Flights for " + numberOfPersons + " " + (numberOfPersons > 1 ? "Persons" : "Person")
    } else if (bookingSearchInfo.HotelsData != null && bookingSearchInfo.HotelsData.length > 0) {
        tagText = "Hotels for " + numberOfPersons + " " + (numberOfPersons > 1 ? "Persons" : "Person")
    }
    return tagText;
}

export default PrintTicket;