import moment from 'moment';
import React from 'react';
import { LIGHT_TEXT_COLOR } from '../constants/global';

const GetFlightSection = ({ planeType, airlineName, image, travelStartAt, fromAirport, stops, duration, travelEndAt, toAirport, isMenu, onMenuPress, bookingSearchInfo, borderLess, durationLess }) => {
    return <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingTop: 10, paddingBottom: 10, paddingRight: 20, borderBottomStyle: 'solid', borderBottomWidth: borderLess ? 0 : 1, borderBottomColor: '#ddd' }} >
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: 60, justifyContent: 'center', borderRightWidth: 1, borderRightColor: '#ddd', borderRightStyle: 'solid' }} >
            <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }} >
                <div style={{ alignItems: 'center', marginLeft: 6, marginRight: 6 }} >
                    <img style={{ flex: 1, height: 30, width: 30, alignSelf: 'stretch' }} src={image} />
                </div>
                <span style={{ color: LIGHT_TEXT_COLOR, fontWeight: 'bold', fontSize: 12 }} >{moment(travelStartAt, 'MM/DD/YYYY h:m:s A').format("MM/DD")}</span>
            </div>
        </div>
        <div style={{ flex: 1, display: 'flex', flexDirection: 'row', marginLeft: 15 }} >
            <div style={{ alignItems: 'flex-start', alignSelf: 'stretch', justifyContent: 'flex-end', flex: 1, display: 'flex', flexDirection: 'column' }} >
                <span style={{ fontSize: 14, fontWeight: 'bold' }} >{moment(travelStartAt, 'MM/DD/YYYY h:m:s A').format("hh:mm A")}</span>
                <span style={{ fontSize: 20, color: LIGHT_TEXT_COLOR, fontWeight: '200' }}>{fromAirport}</span>
            </div>
            {!durationLess ? <div style={{ justifyContent: 'center', alignItems: 'center', marginLeft: 10, marginRight: 10, flex: 1, display: 'flex', flexDirection: 'column' }} >
                <span style={{ color: LIGHT_TEXT_COLOR, fontSize: 10 }} >{duration}</span>
                <div style={{ alignSelf: 'stretch', height: 1, backgroundColor: LIGHT_TEXT_COLOR, marginTop: 6, marginBottom: 6 }} />
                <span style={{ color: LIGHT_TEXT_COLOR, fontSize: 10 }} >{stops > 1 ? (stops - 1) + (stops > 2 ? '-STOPS' : '-STOP') : 'NON-STOP'}</span>
            </div> : <div style={{ flex: 1, justifyContent: 'center', marginLeft: 5, marginRight: 5 }} >
                <div style={{ alignSelf: 'stretch', height: 1, backgroundColor: LIGHT_TEXT_COLOR, marginTop: 6, marginBottom: 6 }} />
            </div>}
            <div style={{ alignItems: 'flex-end', alignSelf: 'stretch', justifyContent: 'flex-end', flex: 1, display: 'flex', flexDirection: 'column' }} >
                <span style={{ fontSize: 14, fontWeight: 'bold' }} >{moment(travelEndAt, 'MM/DD/YYYY h:m:s A').format("hh:mm A")}</span>
                <span style={{ fontSize: 20, color: LIGHT_TEXT_COLOR, fontWeight: '200' }}>{toAirport}</span>
            </div>
        </div>
    </div>
}

export default GetFlightSection;