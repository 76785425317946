const AppAssets = {
    background_rotate: require('./background_rotate.png'),
    cancel_ui_image: require('./cancel_ui_image.png'),
    google_icon_100: require('./google_icon_100.png'),
    loading_exif_compressed: require('./loading_exif_compressed.gif'),
    travogram_logo: require('./travogram_logo.png'),
    icons: {
        clock_icon: require('./icons/clock_icon.png'),
        doc_cancel_icon: require('./icons/doc_cancel_icon.png'),
        tour_guide_icon: require('./icons/tour_guide_icon.png'),
        wheel_chair_icon: require('./icons/wheel_chair_icon.png')
    }
}

export default AppAssets