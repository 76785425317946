import { faBed, faCalendar, faChevronDown, faChevronLeft, faChevronUp, faCircle, faDumbbell, faGlassCheers, faMugHot, faParking, faSearch, faSnowflake, faSpa, faStar, faSwimmer, faTimesCircle, faUmbrellaBeach, faUtensils, faWifi } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import getSymbolFromCurrency from "currency-symbol-map";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { GetTotalNights } from "./components/BookingFunctions";
import LoadingView from "./components/LoadingView";
import { GOOGLE_API_KEY, LIGHT_TEXT_COLOR, THEME_COLOR, THEME_COLOR_BLUE } from "./constants/global";
import './components/GetFlightSearchItem.css'
import HotelRoomItem from "./components/HotelRoomItem";
import { Helmet } from "react-helmet";
import PrintDocument from "./components/PrintDocument";
import PaymentItem from "./components/PaymentItem";
import RatingsItem from "./components/RatingsItem";
import SupportComponent from "./components/SupportComponent";

const headerHeight = 60;

let navigationItem = null
let setHotelInfoDataItem = null;
let onHotelRoomChangeItem = null;

let HEADER_EXPANDED_HEIGHT = 240
const HEADER_COLLAPSED_HEIGHT = 40

const HotelView = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [hotelInfoData, setHotelInfoData] = useState(null)
    setHotelInfoDataItem = setHotelInfoData;
    const isBookingDetails = true;
    const hotelData = {}
    const [travellers, setTravellers] = useState({
        "adults": 1,
        "youths": 0,
        "children": 0,
        "infants": 0,
        "infantOnLap": 0,
        "infantOnSeat": 0,
        "rooms": 1,
        "child_age": null
    });

    const [payment, setPayment] = useState([])

    document.sendMessage = (data) => {
        setTravellers({ ...data.travellers })
        setPayment([...data.payment])
        setHotelInfoData({ hotelInfo: data.hotelInfo })
    };

    document.printDocument = () => {
        PrintDocument();
    }

    const [imagesData, setImagesData] = useState([]);
    const [imagesIndex, setImagesIndex] = useState(0);

    useEffect(() => {

    }, [])

    const scrollViewRef = useRef();
    let scrollHeight = 0;
    useEffect(() => {

    }, [])

    const onMessageReceivedFromIframe = event => {
        const eventData = event.data;
        if (eventData.type == "TravogramWebMessage") {
            const data = eventData.data;
            setTravellers({ ...data.travellers })
            setPayment([...data.payment])
            setHotelInfoData({ hotelInfo: data.hotelInfo })
        }
    }
    useEffect(() => { window.addEventListener("message", onMessageReceivedFromIframe); return () => window.removeEventListener("message", onMessageReceivedFromIframe); }, []);


    return <div style={{ flex: 1 }} >
        <Helmet >
            <title>Hotel View</title>
        </Helmet>
        {hotelInfoData == null ? <LoadingView /> : <div style={{ flex: 1 }} >
            <div style={{ flex: 1 }} >
                <div ref={scrollViewRef} style={{ flex: 1 }} >
                    <div style={{ display: 'flex' }} >
                        <HeaderContent viewImages={hotelInfoData.hotelInfo.media} isBookingCancelled={hotelInfoData.hotelInfo.isBookingCancelled} setImagesData={(imagesList, index) => { setImagesData(imagesList); setImagesIndex(index) }} />
                    </div>
                    <div style={{ borderTopRightRadius: 40, borderTopLeftRadius: 40, backgroundColor: 'white', flex: 1, display: 'flex', marginTop: 20 }} >
                        {hotelInfoData.hotelInfo != null && <HotelContent hotelInfoData={hotelInfoData} travellers={travellers} setImagesData={setImagesData} isBookingDetails={isBookingDetails} paymentInfo={payment} />}
                    </div>
                </div>
                {/* <HeaderButtons /> */}
            </div>
            {/* <ImageView
                images={imagesData}
                imageIndex={imagesIndex}
                visible={imagesData.length > 0}
                onRequestClose={() => { setImagesData([]); setImagesIndex(0) }}
            /> */}
        </div>}
    </div>
}

const GetImagesList = (imageList) => {
    const imagesData = [];
    imageList.forEach((element) => {
        imagesData.push({ uri: element })
    })
    return imagesData;
}

const HeaderButtons = () => {
    return <div style={{ paddingTop: 10, paddingBottom: 10, alignSelf: 'stretch', flexDirection: 'row', position: 'absolute', top: 0, left: 0, right: 0, alignItems: 'center' }} pointerEvents="box-none" >
        <div style={{ flexDirection: 'row', alignItems: 'center' }} >
            <button style={{ backgroundColor: 'transparent', padding: 0, width: 40, height: 40, justifyContent: 'flex-start', paddingLeft: 10 }} ><FontAwesomeIcon icon={faChevronLeft} color="white" size={20} /></button>
            <div style={{ flex: 1 }} pointerEvents="none" />
            <div underlayColor="transparent" style={{ marginRight: 10 }} onPress={() => { }}  >
                <div style={{ width: 40, height: 40, alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{}}>
                        <FontAwesomeIcon icon={faSearch} size={18} color={"white"} style={{}} />
                    </div>
                </div>
            </div>
        </div>
    </div>
}

const GetSelectedRoom = (hotelRooms) => {
    if (hotelRooms.length == 1) return hotelRooms[0];
    for (let indexI = 0; indexI < hotelRooms.length; indexI++) {
        if (hotelRooms[indexI].isRoomSelected) return hotelRooms[indexI]
    }
    return {};
}

const HotelContent = ({ hotelInfoData, travellers, setImagesData, isBookingDetails, paymentInfo }) => {
    const [isMoreSummary, setIsMoreSummary] = useState(true)
    const hotelRatings = ["Very Bad", "Bad", "Moderate", "Good", "Excellent"];
    let MapRef = null;

    const totalNights = GetTotalNights(hotelInfoData.hotelInfo)
    const selectedRoom = GetSelectedRoom(hotelInfoData.hotelInfo.hotelRooms);

    return <div style={{ flex: 1, paddingLeft: 20, paddingRight: 20, paddingTop: 20, paddingBottom: 20 }} >
        <div style={{ flex: 1 }} >
            <div style={{ flex: 1 }} >
                <div style={{ display: 'flex', flexDirection: 'row', alignSelf: 'stretch', alignItems: 'flex-start' }} >
                    <span style={{ fontSize: 18, flex: 1, flexWrap: 'wrap', alignItems: 'flex-start', fontWeight: '600' }} >{hotelInfoData.hotelInfo.hotelName}</span>
                    <span style={{ fontSize: 20, color: THEME_COLOR, fontWeight: '800' }} >{getSymbolFromCurrency(hotelInfoData.currencyCode)} {parseFloat(selectedRoom.pricePerNight).toLocaleString()}</span>
                </div>
                <div style={{ flex: 1, display: 'flex', flexDirection: 'row', marginTop: 10, marginBottom: 15 }} >
                    <RatingsItem rating={hotelInfoData.hotelInfo?.rating} googleRating={hotelInfoData.hotelInfo?.googleRatings} googleReviews={hotelInfoData.hotelInfo?.numGoogleReviews} googleReviewLink={hotelInfoData.hotelInfo?.googleReviewsLink} />
                    <div style={{ flex: 1 }} />
                    <span style={{ fontWeight: 'bold', fontSize: 10, color: LIGHT_TEXT_COLOR, paddingLeft: 8, paddingRight: 8, paddingTop: 4, paddingBottom: 4, backgroundColor: 'white', borderWidth: 1, borderColor: '#ddd', borderRadius: 6, borderStyle: 'solid' }} numberOfLines={1} >{selectedRoom.roomType}</span>
                </div>
                <div style={{ height: 1, flex: 1, display: 'flex', backgroundColor: '#ddd', marginBottom: 15 }} />
            </div>
            <span style={{ fontSize: 16, fontWeight: '600' }} >Summary</span>
            {hotelInfoData.hotelInfo.summary != null && <p numberOfLines={isMoreSummary ? 6 : 0} style={{ color: '#606060', fontWeight: '400', fontSize: 14, padding: 0, margin: 0, marginTop: 10, marginBottom: 20 }} >{hotelInfoData.hotelInfo.summary}</p>}
            {/* <span style={{ fontSize: 16, fontWeight: '600' }} >Amenities</span>
            {hotelInfoData.hotelInfo.amenities != null || true && <div style={{ alignSelf: 'stretch', display: 'flex', gap: 5, flexWrap: 'wrap', marginTop: 10 }} >
                <AmenityItem icon={faWifi} name="Free Wifi" isActive={hotelInfoData.hotelInfo?.amenities?.includes("WIFI")} />
                <AmenityItem icon={faSpa} name="Spa" isActive={hotelInfoData.hotelInfo?.amenities?.includes("SPA")} />
                <AmenityItem icon={faUmbrellaBeach} name="Beach" isActive={hotelInfoData.hotelInfo?.amenities?.includes("BEACH")} />
                <AmenityItem icon={faSnowflake} name="Air Condtion" isActive={hotelInfoData.hotelInfo?.amenities?.includes("AIR_CONDITION")} />
                <AmenityItem icon={faUtensils} name="Restaurant" isActive={hotelInfoData.hotelInfo?.amenities?.includes("RESTAURANT")} />
                <AmenityItem icon={faMugHot} name="Breakfast" isActive={hotelInfoData.hotelInfo?.amenities?.includes("COFFEE_SHOP")} />
                <AmenityItem icon={faParking} name="Parking" isActive={hotelInfoData.hotelInfo?.amenities?.includes("PARKING")} />
                <AmenityItem icon={faDumbbell} name="Gym" isActive={hotelInfoData.hotelInfo?.amenities?.includes("GYM")} />
                <AmenityItem icon={faGlassCheers} name="Bar" isActive={hotelInfoData.hotelInfo?.amenities?.includes("BAR")} />
                <AmenityItem icon={faSwimmer} name="pool" isActive={hotelInfoData.hotelInfo?.amenities?.includes("SWIMMING_POOL")} />
            </div>} */}
            {/* <div onClick={() => setIsMoreSummary(!isMoreSummary)} ><span style={{ textDecorationLine: 'underline', paddingTop: 5, paddingBottom: 5 }} >See {isMoreSummary ? "more" : "less"} <FontAwesomeIcon icon={isMoreSummary ? faChevronDown : faChevronUp} /></span></div> */}
            <div style={{ flex: 1, marginTop: 20, marginBottom: 20 }} >
                <div style={{ display: 'flex', flexDirection: 'row', flex: 1, alignItems: 'center', marginBottom: 10 }} >
                    <span style={{ fontSize: 16, fontWeight: '600' }} >Booking Date and Details</span>
                    <div style={{ flex: 1 }} />
                    {/* <Button title="Change" titleStyle={{ color: THEME_COLOR, fontSize: 14, fontWeight: '600' }} buttonStyle={{ padding: 0, backgroundColor: 'transparent' }} onPress={() => DateSelectionModalSheetRef.open(null, null, null, null, () => { }, true)} /> */}
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }} >
                    <div style={{ flex: 1, display: 'flex', flexDirection: 'row', marginLeft: 0 }} >
                        <div style={{ flex: 1, paddingTop: 10, paddingBottom: 10, paddingLeft: 10, paddingRight: 10, borderRadius: 20, borderWidth: 1, borderColor: '#ddd', borderStyle: 'solid' }} >
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 4 }} >
                                <span style={{ fontSize: 10 }} >Check-in</span>
                                <div style={{ flex: 1 }} />
                                <div style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 4, paddingBottom: 4, backgroundColor: 'rgba(221, 221, 221, 0.4)', borderRadius: 10 }} >
                                    <span style={{ fontSize: 8 }} >After 12 PM</span>
                                </div>
                            </div>
                            <span style={{ fontSize: 16, fontWeight: 'bold' }} >{hotelInfoData.hotelInfo.fromDate}</span>
                        </div>
                        <div style={{ width: 10 }} />
                        <div style={{ flex: 1, paddingTop: 10, paddingBottom: 10, paddingLeft: 10, paddingRight: 10, borderRadius: 20, borderWidth: 1, borderColor: '#ddd', borderStyle: 'solid' }} >
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 4 }} >
                                <span style={{ fontSize: 10 }} >Check-out</span>
                                <div style={{ flex: 1 }} />
                                <div style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 4, paddingBottom: 4, backgroundColor: 'rgba(221, 221, 221, 0.4)', borderRadius: 10 }} >
                                    <span style={{ fontSize: 8 }} >Before 11 AM</span>
                                </div>
                            </div>
                            <span style={{ fontSize: 16, fontWeight: 'bold' }} >{hotelInfoData.hotelInfo.toDate}</span>
                        </div>
                    </div>
                </div>
                {/* <div style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', display: 'flex', marginTop: 10, paddingBottom: 10, borderBottomWidth: 1, borderBottomColor: 'rgba(211, 211, 211, 0.4)', marginBottom: 10, borderBottomStyle: 'solid' }} >
                    {selectedRoom.cancelPolicy != null && selectedRoom.cancelPolicy.length > 0 ? <span style={{ flex: 1, color: 'black', fontSize: 12, fontWeight: '400', textDecorationLine: 'underline' }} >Fully Refundable before {moment(selectedRoom.cancelPolicy[0].cancellationDeadline, 'MM/DD/YYYY').format('ddd, MMM D')}</span>
                        : <span style={{ flex: 1, color: 'black', fontSize: 12, fontWeight: '400', }} >Non Refundable</span>}
                    <span style={{ marginLeft: 10, paddingLeft: 8, paddingRight: 8, paddingTop: 4, paddingBottom: 4, backgroundColor: 'white', borderWidth: 1, borderColor: '#ddd', borderRadius: 6, borderStyle: 'solid', fontWeight: 'bold', fontSize: 10, color: LIGHT_TEXT_COLOR }} numberOfLines={1} >{selectedRoom.roomType}</span>
                </div>
                <div style={{ flexDirection: 'row', alignItems: 'center', display: 'flex' }} >
                    <div style={{ flex: 1, flexDirection: 'column', display: 'flex' }} >
                        <div style={{ flexDirection: 'column', display: 'flex' }} >
                            <span style={{ color: 'black', fontSize: 10 }} >{getSymbolFromCurrency(hotelInfoData.currencyCode)}{parseFloat(selectedRoom.totalPrice).toLocaleString()} for {totalNights + (totalNights > 1 ? ' Nights' : ' Night')}, {selectedRoom.numberOfRooms + (selectedRoom.numberOfRooms > 1 ? ' Rooms' : ' Room')}</span>
                            <span style={{ color: 'black', fontSize: 10 }} >Included Taxes and Fees</span>
                        </div>
                    </div>
                    <div style={{ flex: 1, alignItems: 'flex-end', flexDirection: 'column', display: 'flex' }} >
                        <span style={{ fontSize: 20, color: THEME_COLOR, fontWeight: '700' }} >{getSymbolFromCurrency(hotelInfoData.currencyCode)} {parseFloat(selectedRoom.pricePerNight).toLocaleString()}</span>
                        <span style={{ fontSize: 12, color: LIGHT_TEXT_COLOR, marginLeft: 5 }} >per night per room</span>
                    </div>
                </div> */}
            </div>
            <div style={{ flex: 1, height: 200, }} >
                <div style={{ height: '100%', width: '100%' }} onPress={() => { }} >
                    <div style={{ height: '100%', width: '100%', borderRadius: 10, overflow: 'hidden' }} >
                        <iframe
                            style={{ flex: 1, width: '100%', height: '100%', borderWidth: 0 }}
                            loading="lazy"
                            allowfullscreen
                            referrerpolicy="no-referrer-when-downgrade"
                            src={"https://www.google.com/maps/embed/v1/place?key=" + GOOGLE_API_KEY + "&q=" + hotelInfoData.hotelInfo?.latitude + "," + hotelInfoData.hotelInfo?.longitude}>
                        </iframe>
                        <div style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0 }} />
                    </div>
                </div>
            </div>
            <div style={{ flex: 1 }} >
                {hotelInfoData.hotelInfo.hotelRooms.sort(element => element.isRoomSelected ? -1 : 1).map(item => <HotelRoomItem
                    isHotelRoom={true}
                    is
                    cheapRoomPrice={hotelInfoData.hotelInfo.cheapRoomPrice}
                    totalNights={GetTotalNights(hotelInfoData.hotelInfo)}
                    data={item}
                    hotelInfoData={hotelInfoData}
                    setImagesData={setImagesData}
                    isGuestNameVisible={true}
                    onRoomChange={!isBookingDetails ? (selectedCount) => {
                        item.numberOfRooms = parseInt(selectedCount);
                        if (setHotelInfoDataItem != null) setHotelInfoDataItem({ ...hotelInfoData })
                        if (onHotelRoomChangeItem != null) onHotelRoomChangeItem(item)
                    } : undefined}
                    onSelect={!isBookingDetails ? () => {
                        item.numberOfRooms = travellers.rooms;
                        if (setHotelInfoDataItem != null) setHotelInfoDataItem({ ...hotelInfoData })
                        if (onHotelRoomChangeItem != null) onHotelRoomChangeItem(item)
                        navigationItem.goBack();
                    } : undefined}
                />)}
            </div>
            {paymentInfo.map(element => <PaymentItem paymentInfo={element} />)}
            {isBookingDetails && <div className="box-shadow" style={{ borderRadius: 10, marginBottom: 10, marginTop: 10 }} >
                <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 20, marginTop: 20, paddingBottom: 10, paddingLeft: 10, paddingRight: 10, borderBottomColor: '#ddd', borderBottomWidth: 1, borderBottomStyle: 'solid' }} >
                    <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }} >
                        <span style={{ fontSize: 25, fontWeight: '800' }} >Total Price</span>
                        <span style={{ fontSize: 10, color: LIGHT_TEXT_COLOR, fontWeight: 'bold', flex: 1, marginRight: 10 }} >{totalNights + (totalNights > 1 ? ' Nights' : ' Night')}, {selectedRoom.numberOfRooms + (selectedRoom.numberOfRooms > 1 ? ' Rooms' : ' Room')}</span>
                    </div>
                    <div >
                        {/* <Text style={{ fontSize: 14, color: LIGHT_TEXT_COLOR, fontWeight: 'bold', textDecorationLine: 'line-through' }} >{getSymbolFromCurrency(this.state.bookingSearchInfo.currency)} {parseFloat(this.state.bookingSearchInfo.actualCost).toLocaleString()}</Text> */}
                        <span style={{ fontSize: 25, fontWeight: '800', color: THEME_COLOR }} >{getSymbolFromCurrency(hotelInfoData.currencyCode)} {parseFloat(selectedRoom.totalPrice).toLocaleString()}</span>
                    </div>
                </div>
                <div style={{ padding: 10 }} >
                    <span style={{ fontSize: 12, fontWeight: 'bold', color: LIGHT_TEXT_COLOR }} >Prices in USD. Prices includes all <span style={{ color: THEME_COLOR_BLUE }} >taxes and fees</span> charged by Travogram Inc.</span>
                </div>
            </div>}
            <SupportComponent />
            <div style={{ height: 100 }} />
        </div>
    </div>
}

const AmenityItem = ({ icon, name, isActive }) => {
    return <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', width: 80, height: 80, flexDirection: 'column', backgroundColor: 'rgba(211, 211, 211, 0.4)', borderRadius: 10 }} >
        <FontAwesomeIcon icon={icon} color={isActive ? THEME_COLOR_BLUE : LIGHT_TEXT_COLOR} size="xl" />
        <span style={{ color: isActive ? THEME_COLOR_BLUE : LIGHT_TEXT_COLOR, fontSize: 14, marginTop: 5, textOverflow: 'ellipsis', overflow: 'hidden', maxLines: 1 }} >{name}</span>
    </div>
}

const HeaderContent = ({ viewImages, isBookingCancelled, setImagesData }) => {
    const [tabIndex, setTabIndex] = useState(0)
    return <div style={{ height: HEADER_EXPANDED_HEIGHT, position: 'relative', }} >
        <div style={{ position: 'relative', overflow: 'hidden', height: '100%', margin: 20, borderRadius: 20 }} >
            <img src={viewImages[0]} style={{ width: '100%', height: '100%', }} />
            {isBookingCancelled && <div style={{ display: 'flex', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, alignItems: 'center', justifyContent: 'center' }} pointerEvents="none" >
                <div style={{ width: 120, height: 120, borderRadius: 20, rotate: '315deg' }} >
                    <img src={require('./assets/cancel_ui_image.png')} style={{ width: '100%', height: '100%' }} />
                </div>
            </div>}
        </div>
        <div style={{}} pointerEvents="none" />
        {/* <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, padding: 15, paddingBottom: 0, flex: 1, alignItems: 'center' }} pointerEvents="none" >
            <div style={{ flex: 1, minHeight: 40, alignSelf: 'stretch' }} pointerEvents="none" />
            <div style={{ marginBottom: 20, alignSelf: 'stretch', flexDirection: 'row', justifyContent: 'center', display: 'flex' }} pointerEvents="none" >
                {[...Array(viewImages != null ? viewImages.length : 1)].map((item, index) => <FontAwesomeIcon icon={faCircle} color="white" style={{ marginHorizontal: 2, fontSize: 10 }} solid={index == tabIndex ? true : false} />)}
            </div>
        </div> */}
    </div>
}

// const ViewImagesItemsList = React.memo(({ viewImages, onPageSelectedListener, setImagesData }) => {
//     return <div style={{ width: '100%', height: '100%' }} >
//         {viewImages != null && <PagerView style={{ flex: 1 }} onPageSelected={onPageSelectedListener} >
//             {viewImages.map((item, index) => {
//                 return <ViewImagesItem item={item} index={index} onPress={(index) => setImagesData(GetImagesList(viewImages), index)} />
//             })}
//         </PagerView>}
//     </div>
// })

// const ViewImagesItem = React.memo(({ item, index, onPress }) => {
//     AppIndex.AppLibs.AppConsoleLog('ViewImagesHeaderItem', item)
//     return <View style={{ height: '100%', width: '100%' }}  >
//         <TouchableWithoutFeedbackReactNative onPress={() => onPress(index)} >
//             <FastImage style={{ width: '100%', height: '100%' }} source={normalisedSource({ uri: item != null ? item : "" })} />
//         </TouchableWithoutFeedbackReactNative>
//     </View>
// })

const normalisedSource = (source) => {
    const normalisedSource = source && typeof source.uri === "string" && !source.uri.split("http")[1] ? null : source;
    return source && source.uri ? normalisedSource : source;
};

export default HotelView;