import { faBed, faCheck, faChevronDown, faChevronUp, faCircle, faInfoCircle, faTimes, faTimesCircle, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import getSymbolFromCurrency from 'currency-symbol-map';
import moment from 'moment';
import React, { useState } from 'react';
import { LIGHT_TEXT_COLOR, THEME_COLOR, THEME_COLOR_BLUE } from '../constants/global';
import './GetFlightSearchItem.css';

const HotelRoomItem = ({ totalNights, onSelect, cheapRoomPrice, onRoomChange, isHotelRoom, data, setImagesData, isCheckout, setHotelRoomData, isGuestNameVisible, onPriceDetailsClose }) => {
    const [isDescription, setIsDescription] = useState(false)
    const [isPriceDetails, setIsPriceDetails] = useState(true);
    // const isSelected = data.numberOfRooms > 0;
    const isSelected = data.isRoomSelected;

    return <div >
        {isHotelRoom && <div className='box-shadow' style={{ padding: 15, marginTop: 10, marginBottom: 10, borderRadius: 20, alignSelf: 'stretch' }} >
            <div style={{ display: 'flex', flexDirection: 'row', flex: 1, alignItems: 'center', marginBottom: 10 }} >
                <div style={{ alignItems: 'center', justifyContent: 'center', marginRight: 10 }} >
                    <FontAwesomeIcon icon={faBed} color={THEME_COLOR} size={16} />
                </div>
                <span style={{ fontSize: 16, flex: 1, fontWeight: '600' }} >{data.roomType?.split(",")[0]}</span>
                <div style={{ width: 20 }} />
                <div style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 4, paddingBottom: 4, backgroundColor: 'rgba(211, 211, 211, 0.4)', borderRadius: 40 }} >
                    <span style={{ fontSize: 10, color: 'black', fontWeight: 'bold', margin: 0, padding: 0 }} >{data.cancelPolicy != null && data.cancelPolicy.length > 0 ? "Fully Refundable" : "Non-Refundable"}</span>
                </div>
            </div>
            {isDescription && <div style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 8, paddingBottom: 8, backgroundColor: '#f2f2f2', borderRadius: 8 }} >
                <span style={{ color: LIGHT_TEXT_COLOR, fontSize: 12 }} >{data.remarks}</span>
            </div>}
            {data.media != null && data.media.length > 0 && <div onClick={() => { if (setImagesData) setImagesData(GetImagesList(data.media)) }} style={{ position: 'relative' }} >
                <div style={{ height: 140, flex: 1, marginTop: 10, display: 'flex' }} >
                    <img src={data.media != null && data.media.length > 0 ? data.media[0] : ''} style={{ borderRadius: 10, flex: 1 }} />
                    <div style={{ paddingLeft: 8, paddingRight: 8, paddingTop: 6, paddingBottom: 6, position: 'absolute', top: 10, left: 10, backgroundColor: 'rgba(212,175,55, 0.8)', borderRadius: 20 }} >
                        <span style={{ color: 'white', fontWeight: 'bold', fontSize: 12 }} >{data.roomCategory}</span>
                    </div>
                </div>
            </div>}
            <div >
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10, paddingBottom: 10, borderBottomWidth: 1, borderBottomStyle: 'solid', borderBottomColor: '#ddd' }} >
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }} >
                        <span style={{ color: LIGHT_TEXT_COLOR, fontSize: 10 }} >{getSymbolFromCurrency(data.currency)}{parseFloat(data.totalPrice).toLocaleString()} for {totalNights + (totalNights > 1 ? ' Nights' : ' Night')}, {(!data.numberOfRooms || data.numberOfRooms == 0 ? 1 : data.numberOfRooms) + ((!data.numberOfRooms || data.numberOfRooms == 0 ? 1 : data.numberOfRooms) > 1 ? ' Rooms' : ' Room')}</span>
                        <span style={{ color: LIGHT_TEXT_COLOR, fontSize: 10 }} >Included Taxes and Fees</span>
                    </div>
                    <div style={{ alignItems: 'flex-end', display: 'flex', flexDirection: 'column' }} >
                        <span style={{ fontSize: 24, color: THEME_COLOR, fontWeight: 'bold' }} >{getSymbolFromCurrency(data.currency)} {data.pricePerNight}</span>
                        {/* {false && cheapRoomPrice != undefined && cheapRoomPrice != data.totalPrice && <span style={{ fontSize: 12, color: LIGHT_TEXT_COLOR, fontWeight: 'bold' }} >{cheapRoomPrice < data.totalPrice ? '+' : cheapRoomPrice > data.totalPrice ? '-' : ''} {parseFloat(cheapRoomPrice != data.totalPrice ? Math.abs(cheapRoomPrice - data.totalPrice) : data.totalPrice).toFixed(2).toLocaleString()} {getSymbolFromCurrency(data.currency)}</span>} */}
                        <span style={{ fontSize: 10, color: LIGHT_TEXT_COLOR }} >Per Room Per Night</span>
                    </div>
                </div>
                <div style={{ marginTop: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', alignSelf: 'stretch' }} >
                    {onSelect && <button
                        onPress={() => {
                            if (isSelected) return;
                            // hotelInfoData.hotelInfo.cheapRoomPrice = data.totalPrice;
                            // hotelInfoData.hotelInfo.cheapRoomOfferId = data.offerId;
                            // hotelInfoData.hotelInfo.roomChargeType = data.roomChargeType;
                            // hotelInfoData.hotelInfo.roomType = data.roomType;
                            // if (setHotelInfoDataItem != null) setHotelInfoDataItem({ ...hotelInfoData })
                            // if (onHotelRoomChangeItem != null) onHotelRoomChangeItem(data)
                            // navigationItem.goBack()

                            onSelect()
                        }}
                        title={isSelected ? "Selected" : "Select"}
                        titleStyle={{ fontSize: 12, fontWeight: 'bold', color: isSelected ? 'white' : THEME_COLOR }}
                        buttonStyle={{ backgroundColor: isSelected ? THEME_COLOR : 'white', borderRadius: 20, width: '100%', paddingTop: 4, paddingBottom: 4, width: 100, borderWidth: 2, borderColor: THEME_COLOR }} />}
                    <div  >
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingTop: 6, paddingBottom: 6, paddingLeft: 14, paddingRight: 14, backgroundColor: 'rgba(211, 211, 211, 0.4)', borderRadius: 40 }} >
                            <span style={{ fontSize: 16, color: LIGHT_TEXT_COLOR, fontWeight: 'bold' }} >{data.numberOfRooms ? data.numberOfRooms : 0} {data.numberOfRooms > 1 ? 'Rooms' : 'Room'}</span>
                        </div>
                    </div>
                    <div style={{ flex: 1 }} />
                    {data.cancelPolicy != null && data.cancelPolicy.length > 0 ? <div style={{ alignItems: 'flex-end' }} >
                        <span style={{ color: 'black', fontSize: 12, fontWeight: '400', textDecorationLine: 'underline' }} >Fully Refundable before {moment(data.cancelPolicy[0].cancellationDeadline, 'MM/DD/YYYY').format('MMM D')}</span>
                    </div> : <div style={{ alignItems: 'flex-end' }} >
                        <span style={{ color: 'black', fontSize: 12, fontWeight: '400', textDecorationLine: 'underline' }} >Non-Refundable</span>
                    </div>}
                </div>
            </div>
            {false && <div style={{ paddingTop: 5, paddingBottom: 5 }} >
                {[...Array(data.numberOfRooms ? data.numberOfRooms : 1)].map((element, index) => <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 5, marginBottom: 5 }} >
                    <span style={{ fontWeight: 'bold' }} >Room {index + 1}</span>
                    <FontAwesomeIcon icon={faUserCircle} size={24} style={{ marginLeft: 10, marginRight: 10 }} />
                    <input
                        value={data.guestDetails && data.guestDetails != null && data.guestDetails[index] && data.guestDetails[index].roomOwner ? data.guestDetails[index].roomOwner : ''}
                        placeholder={'Guest name'}
                        placeholderTextColor={data.guestDetails && data.guestDetails != null && data.guestDetails[index] && data.guestDetails[index]?.isRoomOwnerError ? "red" : "#ddd"}
                        onChangeText={text => {
                            if (!data.guestDetails || data.guestDetails == null) data.guestDetails = []
                            if (!data.guestDetails[index]) data.guestDetails[index] = { roomOwner: text }; else data.guestDetails[index].roomOwner = text;
                            if (setHotelRoomData) setHotelRoomData(data);
                        }}
                        style={{ flex: 1, paddingLeft: 10, paddingRight: 10, height: 30, borderRadius: 40, borderWidth: 1, borderColor: '#ddd', borderStyle: 'solid' }} />
                </div>)}
            </div>}
            {isGuestNameVisible && data.guestDetails != null && <div style={{ paddingTop: 5, paddingBottom: 5, marginTop: 20 }} >
                {[...Array(data.numberOfRooms ? data.numberOfRooms : 1)].map((element, index) => <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 5, marginBottom: 5 }} >
                    <span style={{ fontWeight: 'bold' }} >Room {index + 1}</span>
                    <FontAwesomeIcon icon={faUserCircle} size={24} style={{ marginLeft: 10, marginRight: 10 }} />
                    <div style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 8, paddingBottom: 5, backgroundColor: '#ddd', borderRadius: 40, flex: 1 }} ><span style={{ fontWeight: 'bold', color: 'black', }} >{data.guestDetails && data.guestDetails != null && data.guestDetails[index] && data.guestDetails[index].roomOwner ? data.guestDetails[index].roomOwner : ''}</span></div>
                </div>)}
            </div>}
        </div>}
        {isPriceDetails && <div className='box-shadow' style={{ marginTop: 5, marginBottom: 20, padding: 5, borderRadius: 20, alignSelf: 'stretch' }} >
            <div style={{ display: 'flex', position: 'relative', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', paddingTop: 15, paddingBottom: 15, borderBottomColor: '#ddd', borderBottomWidth: 1, borderBottomStyle: 'solid' }} >
                <span style={{ color: 'black', fontSize: 14, fontWeight: 'bold', padding: 5, }} >Price details</span>
                <div style={{ marginTop: -20 }} onClick={() => {
                    if (isHotelRoom) setIsPriceDetails(false)
                    if (onPriceDetailsClose) onPriceDetailsClose()
                }}>
                    {/* <FontAwesomeIcon icon={faTimes} style={{ fontSize: 24, color: 'black', position: 'absolute', left: 15 }} /> */}
                </div>
            </div>
            <div style={{ padding: 10 }} ><span style={{ fontSize: 12, color: LIGHT_TEXT_COLOR, fontWeight: '600' }} >{data.remarks}</span></div>
            {data.priceDetails.included.length > 0 && <div style={{ padding: 10, borderBottomWidth: 1, borderBottomColor: "#ddd", borderBottomStyle: 'solid', borderTopWidth: 1, borderTopColor: '#ddd', borderTopStyle: 'solid' }} >
                {data.priceDetails.included.map(element => <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 12 }} >
                    <div style={{ flex: 1 }} >
                        <span style={{ fontSize: 12, color: LIGHT_TEXT_COLOR, fontWeight: '600' }} >{element.chargeType}</span>
                        {element.subChargeType != "" && <span style={{ fontSize: 10, color: LIGHT_TEXT_COLOR, fontWeight: '600' }} >{element.subChargeType}</span>}
                    </div>
                    <span style={{ fontSize: 14, color: LIGHT_TEXT_COLOR, fontWeight: 'bold' }} >{getSymbolFromCurrency(data.currency)}{parseFloat(element.price).toLocaleString()}</span>
                </div>)}
            </div>}
            {data.priceDetails.excluded.length > 0 && <div style={{ padding: 10, }} >
                {data.priceDetails.excluded.map(element => <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 12 }} >
                    <div style={{ flex: 1 }} >
                        <span style={{ fontSize: 12, color: LIGHT_TEXT_COLOR, fontWeight: '600' }} >{element.chargeType}</span>
                        {element.subChargeType != "" && <span style={{ fontSize: 10, color: LIGHT_TEXT_COLOR, fontWeight: '600' }} >{element.subChargeType}</span>}
                    </div>
                    <span style={{ fontSize: 14, color: LIGHT_TEXT_COLOR, fontWeight: 'bold' }} >- {getSymbolFromCurrency(data.currency)}{parseFloat(element.price).toLocaleString()}</span>
                </div>)}
            </div>}
            {data.cancelPolicy && data.cancelPolicy != null && data.cancelPolicy.length > 0 && <div style={{ padding: 10, borderTopStyle: 'solid', borderTopWidth: 1, borderTopColor: "#ddd" }} >
                {data.cancelPolicy && data.cancelPolicy != null && data.cancelPolicy.length > 0 && <div style={{ flexDirection: 'row', alignItems: 'flex-start', marginBottom: 6 }} >
                    <FontAwesomeIcon icon={faCircle} color='black' size='2xs' solid style={{ marginTop: 4 }} />
                    <span style={{ fontSize: 12, color: LIGHT_TEXT_COLOR, marginLeft: 6, fontWeight: '600' }}  >Cancel your reservation before {moment(data.cancelPolicy[0].cancellationDeadline, 'MM/DD/YYYY').format('MMM D, YYYY')} 12:00 am to get full refund</span>
                </div>}
                {data.cancelPolicy && data.cancelPolicy != null && data.cancelPolicy.length > 0 && <div style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 6 }} >
                    <FontAwesomeIcon icon={faCircle} color='black' size='2xs' solid />
                    <span style={{ fontSize: 12, color: LIGHT_TEXT_COLOR, marginLeft: 6, fontWeight: '600' }}  >Times are based on property's local time</span>
                </div>}
            </div>}
        </div>}
    </div >
}

const GetImagesList = (imageList) => {
    const imagesData = [];
    imageList.forEach((element) => {
        imagesData.push({ uri: element })
    })
    return imagesData;
}

export default HotelRoomItem;