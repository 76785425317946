import moment from "moment";
import { LIGHT_TEXT_COLOR } from "../constants/global";

const GetTravellersInfo = ({ travellerInfo }) => {
    return <div className="box-shadow" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10, marginTop: 10, marginLeft: 15, marginRight: 15, padding: 10, borderWidth: 1, borderColor: '#ddd', borderRadius: 10 }} >
        <div style={{ marginRight: 20, flex: 1, display: 'flex', flexDirection: 'column' }} >
            <span style={{ fontWeight: 'bold' }}>Traveler information</span>
            {travellerInfo.map(element => <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'stretch', marginTop: 4, marginBottom: 4 }} ><span style={{ flex: 1, fontSize: 14 }} >{`${element.firstName}${element.middleName != null ? ' ' + element.middleName + ' ' : ' '}${element.lastName} (${moment().diff(moment(element.dob, 'MM/DD/YYYY'), 'years')} yrs)`}</span><span style={{ marginTop: 2, fontSize: 14, color: LIGHT_TEXT_COLOR }} >Ticket Number: <span style={{ color: 'black', fontWeight: 'bold' }} >{element.ticketNumber}</span></span></div>)}
        </div>
        {/* <FontAwesomeIcon icon={faChevronRight} color="black" size={16} /> */}
    </div>
}

export default GetTravellersInfo;