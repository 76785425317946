export const apiUrl = 'https://jsonplaceholder.typicode.com/';
export const MAX_IMAGE_SELECTION = 30;
export const LABELS = {
  FAST_CREATE_PIC: 'Fast Create with Pictures',
  SEARCH_COPY_CREATE: 'Search, Copy & Create',
  CREATE_MANUALLY: 'Create Manually',
};
export const primaryColor = '#fff';

export const PRIMARY_COLOR = '#fff';
export const HEADER_TEXT_COLOR = '#1F1F1F';
export const LIGHT_TEXT_COLOR = '#7C818A';
export const THEME_COLOR = '#33B59B';
export const THEME_COLOR_ALPHA = 'rgba(51, 181, 155, 0.2)';
export const THEME_COLOR_BLUE = '#00CAFF';
export const BACKGROUND_COLOR = '#E5E5E5';
export const BLUE_TEXT_COLOR = '#2ABEFEFF';
export const BLUE_BUTTON_COLOR = '#0064fa';
export const THEME_COLOR_RED = '#EB5757';
export const THEME_COLOR_RED_ALPHA = 'rgba(235, 87, 87, 0.2)'

export const BOOKING_ENABLED = true;

export const TIME_CONST = 'MM/DD/YYYY h:m:s A';
export const DATE_CONST = 'MM/DD/YYYY';

export const isDeepLinkingLoaded = false;

export const APP_TYPE = process.env.REACT_APP_APP_TYPE_ENV;
export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY_ENV;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  apiUrl,
  MAX_IMAGE_SELECTION,
  LABELS,
  PRIMARY_COLOR,
  HEADER_TEXT_COLOR, LIGHT_TEXT_COLOR, THEME_COLOR, THEME_COLOR_BLUE, BACKGROUND_COLOR, BLUE_TEXT_COLOR, BLUE_BUTTON_COLOR,
  BOOKING_ENABLED,
  APP_TYPE, GOOGLE_API_KEY,
  isDeepLinkingLoaded
}