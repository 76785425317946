import { faArrowRight, faChevronDown, faChevronRight, faChevronUp, faExchangeAlt, faInfoCircle, faPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import getSymbolFromCurrency from 'currency-symbol-map';
import moment from 'moment';
import React, { useState } from 'react';
import { LIGHT_TEXT_COLOR, THEME_COLOR, THEME_COLOR_ALPHA, THEME_COLOR_BLUE } from '../constants/global';
import { GetTotalSeats, GetTotalSeatsFlights, GetTotalTravellers } from './BookingFunctions';
import GetFlightBaggageInfoItem from './GetFlightBaggageInfoItem';
import GetFlightInfoItem from './GetFlightInfoItem';
import './GetFlightSearchItem.css'
import GetFlightSection from './GetFlightSection';

const GetFlightSearchItem = React.memo(({ travellers, data, bookingSearchInfo, onChange, isSelected, isFlightSearch, selectable, travellerInfo, isExpanded }) => {
    const [isMore, setIsMore] = useState(isExpanded ? true : false)

    const travellersCount = travellers ? GetTotalTravellers(travellers) : 0;
    const seatsCount = travellers ? GetTotalSeats(travellers) : 0;

    return <div>
        <div className='container box-shadow' style={{ position: 'relative' }} >
            <div style={{ borderBottomStyle: 'solid', borderBottomColor: '#ddd', borderBottomWidth: 1, padding: 15, paddingTop: 8, paddingBottom: 8, backgroundColor: THEME_COLOR_ALPHA, borderTopLeftRadius: 10, borderTopRightRadius: 10 }} >
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }} >
                    <FontAwesomeIcon icon={faPlane} color={THEME_COLOR} size="xs" style={{ marginRight: 10 }} />
                    <span style={{ fontSize: 16, color: LIGHT_TEXT_COLOR }} >{data.startFlightCity}</span>
                    <FontAwesomeIcon icon={data.return.length > 0 ? faExchangeAlt : faArrowRight} color={LIGHT_TEXT_COLOR} size="xs" style={{ marginLeft: 10, marginRight: 10 }} />
                    <span style={{ fontSize: 16, color: LIGHT_TEXT_COLOR }} >{data.endFlightCity}</span>
                    <div style={{ flex: 1 }} />
                    <span style={{ color: LIGHT_TEXT_COLOR, fontSize: 14 }}>{moment(data.departure[0].travelStartAt, 'MM/DD/YYYY').format('DD MMM')}{data.return.length > 0 ? ` - ${moment(data.return[0].travelEndAt, 'MM/DD/YYYY').format('DD MMM')}` : ''}</span>
                </div>
            </div>
            <GetFlightSection
                planeType="Depart"
                airlineName={data.departure[0].airlineName}
                image={data.departure[0].image}
                travelStartAt={data.departure[0].travelStartAt}
                fromAirport={data.departure[0].fromAirport}
                stops={data.departure.length}
                duration={data.totalDepartureDuration}
                travelEndAt={data.departure[data.departure.length - 1].travelEndAt}
                toAirport={data.departure[data.departure.length - 1].toAirport}
                bookingSearchInfo={bookingSearchInfo} onMenuPress={() => { }} isMenu={true} />
            {data.return.length > 0 && <GetFlightSection
                planeType="Return"
                airlineName={data.return[0].airlineName}
                image={data.return[0].image}
                travelStartAt={data.return[0].travelStartAt}
                fromAirport={data.return[0].fromAirport}
                stops={data.return.length}
                duration={data.totalReturnDuration}
                travelEndAt={data.return[data.return.length - 1].travelEndAt}
                toAirport={data.return[data.return.length - 1].toAirport}
                bookingSearchInfo={bookingSearchInfo} isMenu={false} />}
            {data.fareFamily != "" && data.fareFamily != null && <div style={{ display: 'flex', alignSelf: 'stretch', justifyContent: 'center', marginTop: - 10 }} >
                <div style={{ display: 'flex', alignSelf: 'center', flexDirection: 'row', alignItems: 'center', paddingLeft: 8, paddingRight: 8, paddingTop: 4, paddingBottom: 4, backgroundColor: 'rgba(211, 211, 211, 0.6)', borderRadius: 40 }} >
                    <span style={{ fontWeight: 'bold', fontSize: 10, color: 'black' }} >{data.fareFamily}</span>
                </div>
            </div>}
            <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: 15, paddingRight: 15, marginTop: 10, alignItems: 'center' }} >
                <div style={{ display: 'flex', flex: 2, flexDirection: 'column' }} >
                    <span style={{ color: 'black', fontSize: 10 }} >{getSymbolFromCurrency(data.currency)}{parseFloat(data.totalPrice).toLocaleString()} for {seatsCount + (seatsCount > 1 ? ' Seats' : ' Seat')}, {travellersCount + (travellersCount > 1 ? ' Travellers' : ' Traveller')}</span>
                    <span style={{ color: 'black', fontSize: 10 }} >Included Taxes and Fees</span>
                </div>
                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'flex-end' }} >
                    <span style={{ fontSize: 20, color: THEME_COLOR, fontWeight: 'bolder' }} >{getSymbolFromCurrency(data.currency)} {parseFloat(data.pricePerSeat).toLocaleString()}</span>
                    <span style={{ fontSize: 10, color: LIGHT_TEXT_COLOR, marginLeft: 5 }} >per person</span>
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: 10, paddingRight: 15, marginTop: 10, marginBottom: 15, alignItems: 'center' }} >
                {/* <button onPress={() => { setIsMore(!isMore) }} style={{ backgroundColor: 'transparent', border: 0 }} >
                    <span style={{ fontSize: 14, marginRight: 10, color: 'black', fontWeight: '600' }} >Flight Details</span>
                </button> */}
                <span style={{ color: 'black', flex: 2, textAlign: 'right', fontSize: 14 }} >Booking # {data.pnrCode}</span>
            </div>
            {data.isBookingCancelled && <div style={{ display: 'flex', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, alignItems: 'center', justifyContent: 'center' }} pointerEvents="none" >
                <div style={{ width: 120, height: 120, borderRadius: 20, rotate: '315deg' }} >
                    <img src={require('../assets/cancel_ui_image.png')} style={{ width: '100%', height: '100%' }} />
                </div>
            </div>}
        </div >
        {isMore && <div className='box-shadow' style={{ marginLeft: 15, marginRight: 15, marginTop: 10, marginBottom: 10, borderRadius: 20, alignSelf: 'stretch', backgroundColor: isMore ? '#fafafa' : 'white' }} >
            <div onPress={() => { }} underlayColor="#ddd" style={{ borderRadius: 20 }} >
                <div style={{ paddingTop: 20, paddingBottom: 20, paddingLeft: 20, paddingRight: 20 }} >
                    <div style={{ marginBottom: 10 }} >
                        {data.departure.map(item => <GetFlightInfoItem data={item} />)}
                        <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', alignSelf: 'stretch', flexDirection: 'row', padding: 10, backgroundColor: '#fafafa', borderWidth: 1, borderColor: '#ddd', borderStyle: 'solid', borderRadius: 10, marginVertical: 10 }} >
                            <span style={{ fontWeight: '200', marginRight: 5, textAlign: 'center' }} >Total Duration</span><span style={{ fontWeight: 'bold' }}>{data.totalDepartureDuration}</span>
                        </div>
                    </div>
                    {data.return.length > 0 && <div >
                        {data.return.map(item => <GetFlightInfoItem data={item} />)}
                        <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', alignSelf: 'stretch', flexDirection: 'row', padding: 10, backgroundColor: '#fafafa', borderWidth: 1, borderColor: '#ddd', borderStyle: 'solid', borderRadius: 10, marginVertical: 10 }} >
                            <span style={{ fontWeight: '200', marginRight: 5, textAlign: 'center' }} >Total Duration</span><span style={{ fontWeight: 'bold' }}>{data.totalReturnDuration}</span>
                        </div>
                    </div>}
                </div>
            </div>
            {isMore && (data.fareFamilyDescrip != null && data.returnFareFamilyDescrip != null) && <div style={{ marginTop: 10, marginBottom: 10, borderRadius: 20, alignSelf: 'stretch', backgroundColor: isMore ? '#fafafa' : 'white' }} >
                <div onPress={() => { }} underlayColor="#ddd" style={{}} >
                    <div style={{ padding: 20 }} >
                        {data.fareFamilyDescrip != null && <GetFlightBaggageInfoItem data={data.fareFamilyDescrip} toAirportCity={data.endFlightCity} />}
                        <div style={{ height: 20 }} />
                        {data.returnFareFamilyDescrip != null && <GetFlightBaggageInfoItem data={data.returnFareFamilyDescrip} toAirportCity={data.startFlightCity} />}
                    </div>
                </div>
            </div>}
        </div>}

    </div>
})

export default GetFlightSearchItem;