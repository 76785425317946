import { faArrowRight, faChevronRight, faExchangeAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import getSymbolFromCurrency from "currency-symbol-map";
import moment from "moment";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { GetTotalSeats, GetTotalSeatsFlights, GetTotalTravellers } from "./components/BookingFunctions";
import GetFlightSearchItem from "./components/GetFlightSearchItem";
import './components/GetFlightSearchItem.css'
import GetTravellersInfo from "./components/GetTravellersInfo";
import LoadingView from "./components/LoadingView";
import PaymentItem from "./components/PaymentItem";
import PrintDocument from "./components/PrintDocument";
import SupportComponent from "./components/SupportComponent";
import { LIGHT_TEXT_COLOR, THEME_COLOR, THEME_COLOR_BLUE } from "./constants/global";

const FlightView = () => {
    const [data, setData] = useState(null)
    const [travellerInfo, setTravellerInfo] = useState([{ "altPhone": null, "country": null, "dob": "11/17/1997", "email": null, "firstName": "Venkata", "gender": null, "internalPassengerOrder": 0, "isAdult": false, "isChild": false, "isInfant": false, "isInfantOnLap": false, "isInfantOnSeat": false, "lastName": "Chaithanya", "middleName": null, "phone": null, "roomNum": 0, "ticketNumber": null, "title": null, "travellerType": null }]);
    const [travellers, setTravellers] = useState({ "adults": 1, "child_age": null, "children": 0, "infantOnLap": 0, "infantOnSeat": 0, "infants": 0, "rooms": 1, "youths": 0 });
    const [payment, setPayment] = useState([]);

    document.sendMessage = (data) => {
        setTravellerInfo([...data.travellerInfo])
        setTravellers({ ...data.travellers })
        setPayment(data.payment)
        setData({ ...data.flightData })
    };

    document.printDocument = () => {
        PrintDocument();
    }

    const onMessageReceivedFromIframe = event => {
        const eventData = event.data;
        if (eventData.type == "TravogramWebMessage") {
            const data = eventData.data;
            setTravellerInfo([...data.travellerInfo])
            setTravellers({ ...data.travellers })
            setPayment(data.payment)
            setData({ ...data.flightData })
        }
    }
    useEffect(() => { window.addEventListener("message", onMessageReceivedFromIframe); return () => window.removeEventListener("message", onMessageReceivedFromIframe); }, []);

    const travellersCount = travellers ? GetTotalTravellers(travellers) : 0;
    const seatsCount = travellers ? GetTotalSeats(travellers) : 0;

    return (<div style={{ flex: 1 }} >
        <Helmet >
            <title>Hotel View</title>
        </Helmet>
        {data == null ? <LoadingView /> : <div style={{ flex: 1, backgroundColor: 'white' }}>
            <div style={{ alignSelf: 'stretch', padding: 20 }} >
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} >
                    <span style={{ fontSize: 28, fontWeight: '600' }}>{data.startFlightCity}</span>
                    <FontAwesomeIcon icon={data.return.length > 0 ? faExchangeAlt : faArrowRight} size={22} style={{ marginLeft: 20, marginRight: 20 }} />
                    <span style={{ fontSize: 28, fontWeight: '600' }}>{data.endFlightCity}</span>
                </div>
                <span style={{ marginTop: 8 }} >Confirmation: {data.pnrCode}</span>
            </div>
            <div style={{}} >
                <GetFlightSearchItem data={data} travellers={travellers} isExpanded={true} />
            </div>
            <GetTravellersInfo travellerInfo={travellerInfo} />
            <div style={{ marginLeft: 15, marginRight: 15 }} >{payment.map(element => <PaymentItem paymentInfo={element} />)}</div>
            <div className="box-shadow" style={{ borderRadius: 10, marginBottom: 20, marginTop: 10, marginLeft: 15, marginRight: 15 }} >
                <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 20, marginTop: 20, paddingBottom: 10, paddingLeft: 10, paddingRight: 10, borderBottomColor: '#ddd', borderBottomWidth: 1 }} >
                    <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }} >
                        <span style={{ fontSize: 25, fontWeight: '800' }} >Total Price</span>
                        <span style={{ fontSize: 10, color: LIGHT_TEXT_COLOR, fontWeight: 'bold', flex: 1, marginRight: 10 }} >{seatsCount + (seatsCount > 1 ? ' Seats' : ' Seat')}, {travellersCount + (travellersCount > 1 ? ' Travellers' : ' Traveller')}</span>
                    </div>
                    <div >
                        {/* <Text style={{ fontSize: 14, color: LIGHT_TEXT_COLOR, fontWeight: 'bold', textDecorationLine: 'line-through' }} >{getSymbolFromCurrency(this.state.bookingSearchInfo.currency)} {parseFloat(this.state.bookingSearchInfo.actualCost).toLocaleString()}</Text> */}
                        <span style={{ fontSize: 25, fontWeight: '800', color: THEME_COLOR }} >{getSymbolFromCurrency(data.currencyCode)} {parseFloat(data.totalPrice).toLocaleString()}</span>
                    </div>
                </div>
                <div style={{ padding: 10 }} >
                    <span style={{ fontSize: 12, fontWeight: 'bold', color: LIGHT_TEXT_COLOR }} >Prices in USD. Prices includes all <span style={{ color: THEME_COLOR_BLUE }} >taxes and fees</span> charged by Travogram Inc.</span>
                </div>
            </div>
            <SupportComponent />
            <div style={{ height: 100 }} />
        </div>}
    </div>
    );
}

export default FlightView;