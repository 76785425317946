import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LIGHT_TEXT_COLOR, THEME_COLOR } from "../constants/global";

const SupportComponent = ({ }) => {
    return <div style={{ paddingLeft: 10, paddingRight: 10, display: 'flex', flexDirection: 'column' }} >
        <span style={{ fontSize: 18, fontWeight: 'bold', color: LIGHT_TEXT_COLOR, marginBottom: 10 }} >We're here to Support ..</span>
        <span style={{ fontSize: 12, fontWeight: 'bold', color: LIGHT_TEXT_COLOR }} >If you need help with this booking, please email us at <a style={{ color: THEME_COLOR }} href="mailto:bookingsupport@travogram.com" >bookingsupport@travogram.com</a> or chat with us on <br /><FontAwesomeIcon icon={faWhatsapp} color={THEME_COLOR} /> <a style={{ color: THEME_COLOR }} href="https://wa.me/message/26UFLHPMRSOON1" >WhatsApp</a></span>
    </div>
}

export default SupportComponent;