import moment from "moment";

export const GetTotalTravellers = (travellers) => {
    if (travellers == null) return 0;
    const travellersKeys = Object.keys(travellers);
    let travellersCount = 0;
    travellersKeys.forEach((element, index) => {
        if (element.toLocaleLowerCase() !== 'rooms' && element !== 'infantSeating' && element !== 'infantOnSeat' && element !== 'infantOnLap' && travellers[element] != null) travellersCount += travellers[element]
    })
    return travellersCount;
}

export const GetTotalSeats = (item) => {
    let seats = 0;
    seats += item.adults;
    seats += item.children;
    seats += item.youths;
    seats += item.infantOnSeat;
    return seats;
}

export const GetTotalSeatsFlights = (item) => {
    let seats = 0;
    seats += item.Adults;
    seats += item.Children;
    seats += item.Youths;
    seats += item.InfantOnSeat;
    return seats;
}

export const GetTotalNights = (item) => {
    return moment(item.toDate, 'MM/DD/YYYY').diff(moment(item.fromDate, 'MM/DD/YYYY'), 'days');
}

export const GetHotelTotalNights = (item) => {
    return moment(item.checkOutDate, 'MM/DD/YYYY').diff(moment(item.checkInDate, 'MM/DD/YYYY'), 'days');
}

export const RemoveMoreFlights = (bookingInfo) => {
    for (let indexI = 0; indexI < bookingInfo.flights.length; indexI++) {
        bookingInfo.flights[indexI].moreFlights = [];
        bookingInfo.flights[indexI].moreResults = [];
    }
    return bookingInfo;
}

export const GetTotalHotelGrossPrice = (hotelRooms) => {
    let totalPrice = 0;
    hotelRooms.forEach(element => totalPrice = totalPrice + element.totalPrice);
    return totalPrice;
}

export const RemoveMoreHotels = (bookingInfo) => {
    for (let indexI = 0; indexI < bookingInfo.hotels.length; indexI++) {
        bookingInfo.hotels[indexI].moreHotels = [];
        bookingInfo.hotels[indexI].moreResults = [];
        bookingInfo.hotels[indexI].amenities = [];
    }
    return bookingInfo;
}

export const GetMintuestoFullDuration = (mins) => {
    const hours = mins / 60
    const rHours = Math.floor(hours)
    const minutes = (hours - rHours) * 60
    const rMinutes = Math.round(minutes)
    return rHours + ' hours ' + rMinutes + ' minutes'
}

export const GetMintuestoDuration = (mins) => {
    const hours = mins / 60
    const rHours = Math.floor(hours)
    const minutes = (hours - rHours) * 60
    const rMinutes = Math.round(minutes)
    return rHours + 'H ' + rMinutes + 'M'
}

export const isSearchFlights = (bookingInfo) => {
    if (bookingInfo.isFlight && !bookingInfo.isLodging && !bookingInfo.isCar && !bookingInfo.isActivity) return true;
    return false;
}

export const isSearchHotels = (bookingInfo) => {
    if (!bookingInfo.isFlight && bookingInfo.isLodging && !bookingInfo.isCar && !bookingInfo.isActivity) return true;
    return false;
}

export const ModifyDataItem = (data, dataItem) => {
    const dataElement = JSON.parse(JSON.stringify(dataItem));
    dataElement.moreResults = data.moreResults;
    return dataElement;
}

export const GetTotalPrice = (bookingSearchInfo) => {
    let totalPrice = 0;
    bookingSearchInfo.flights.forEach(element => {
        totalPrice = totalPrice + element.totalPrice
    });
    bookingSearchInfo.hotels.forEach(element => {
        element.hotelRooms?.forEach(roomElement => {
            totalPrice = totalPrice + roomElement.totalPrice
        })
    });
    return totalPrice;
}

export const GetTotalHotelRooms = (hotelData) => {
    let hotelRooms = 0;
    hotelData.hotelRooms.forEach(element => {
        if (element.numberOfRooms && element.numberOfRooms > 0) hotelRooms = hotelRooms + element.numberOfRooms;
        else hotelRooms++;
    })
    return hotelRooms;
}

export const HotelRoomLogic = (hotelRoomsInput, hotelRoomChanged) => {
    const hotelRoomIndex = HotelRoomIndex(hotelRoomsInput, hotelRoomChanged);
    if (hotelRoomIndex < 0) {
        if (hotelRoomChanged.numberOfRooms > 0) hotelRoomsInput.push(hotelRoomChanged)
    } else {
        if (hotelRoomChanged.numberOfRooms > 0) hotelRoomsInput[hotelRoomIndex] = hotelRoomChanged; else hotelRoomsInput.splice(hotelRoomIndex, 1)
    }
    return hotelRoomsInput
}

export const HotelRoomIndex = (hotelRoomsInput, hotelRoom) => {
    for (let indexI = 0; indexI < hotelRoomsInput.length; indexI++) {
        if (hotelRoomsInput[indexI].roomCode === hotelRoom.roomCode) return indexI;
    }
    return -1;
}

export const SliceMoreResults = (hotelData) => {
    const hotelDataItem = JSON.parse(JSON.stringify(hotelData))
    delete hotelDataItem.moreResults
    return hotelDataItem;
}

export const GetBookingInfoWithTravellers = (data, bookingInfo) => {
    data.travellers = bookingInfo.travellers
    data.preferredClass = bookingInfo.preferredClass
    data.currencyCode = bookingInfo.currencyCode
    data.travelPlanId = bookingInfo.travelPlanId
    data.userId = bookingInfo.userId
    return data;
}

export const GetItemIndex = (items, id) => {
    for (let indexI = 0; indexI < items.length; indexI++) {
        if (id === items[indexI].itemId) return indexI;
    }
    return 0;
}