import './PrivacyPolicy.css'

const PrivacyPolicy = () => {
    return <div style={{ padding: 10 }} >

        <div >

            <div >
                <h3 >Privacy Policy</h3>
            </div>

            <div ><span >Protecting
                your private information is our priority. This Statement of Privacy governs data
                collection and usage. For the purposes of this Privacy Policy, unless otherwise noted, all
                references to Travogram, includes Travogram’s website(s), and any mobile applications (“Site”)
                and Travogram, Inc. (“Travogram”, “We” or “Us”). “You” or “Your” refers to you the user of
                the Site, regardless of whether you a natural person or an entity.
            </span></div><br />
            <div ><span >Travogram is a
                platform which allows users to share travel experiences and book travel through
                third parties.</span></div>

            <div >
                <h4 >Collection of your Personal Information</h4>
            </div>
            <div ><span >Travogram may
                collect personal information, about you, including but not limited to your:</span></div>

            <div  ><span >-</span><span
            > &nbsp;Full Name</span></div>
            <div  ><span >-</span><span
            > &nbsp;Legal Address</span></div>
            <div  ><span >-</span><span
            > &nbsp;Phone Number</span></div>
            <div  ><span >-</span><span
            > &nbsp;Email</span></div>
            <div  ><span >-</span><span
            > &nbsp;Social media profiles</span></div>
            <div  ><span >-</span><span
            > &nbsp;Credit Card Information for processing by a third-party.</span></div>
            <div  ><span >-</span><span
            > &nbsp;Payment processor ID (e.g. without limitation PayPal).</span></div>
            <div  ><span >-</span><span
            > &nbsp;Any other information you voluntarily provide to us, including but not
                limited to itinerary
                details, photos, reviews and comments, and you may have provided to us</span></div> <br />




            <div  ><span >We do not
                collect any personal information about you unless you voluntarily provide it to us.
                However, you may be required to provide certain personal information to us when you elect to
                use certain products or services available on the Site. These may include: (a) registering for an
                account on our Site; (b) entering information for a link sponsored by us or one of our partners;
                (c) signing up for special offers from selected third parties; (d) sending us an email message; (e)
                submitting your credit card or other payment information on the Site. To wit, we will use your
                information for, but not limited to, communicating with you in relation to services and/or
                products you have requested from us. We also may gather additional personal or non-personal
                information in the future. </span></div> <br />
            <div  ><span >We are
                committed to providing you with relevant content on our Services and respect the data
                privacy laws of the different jurisdictions which we operate in. We use information about you so
                we can help you enjoy and use our Services. The reasons we may use your information include
                but are not limited to help you or Us: </span></div><br />

            <div  ><span >●</span><span
            > Create and maintain your account with Us</span></div>
            <div  ><span >●</span><span
            > Facilitate your bookings through third-parties</span></div>
            <div  ><span >●</span><span
            > Monitor and analyze trends, usage, and activities in connection with our Services;
            </span></div>
            <div  ><span >●</span><span
            > Communicate with you about new content, products, services, and features offered by
                us,
                our affiliates, or our partners that may be of interest to you </span></div>
            <div  ><span >●</span><span
            > Enable us to publish your itinerary, reviews, comments, photos and other content
                per the
                Terms of Use </span></div>
            <div  ><span >●</span><span
            > Displaying suitable the ads to you on our Services and across the internet </span>
            </div>
            <div  ><span >●</span><span
            > Provide you with an optimized experience based on, for example, usage and
                demographics </span></div>
            <div  ><span >●</span><span
            > Send you survey or market research invitations </span></div>
            <div  ><span >●</span><span
            > Provide customer service or respond to inquiries; </span></div>
            <div  ><span >●</span><span
            > Detect, investigate, and prevent security incidents and other malicious, deceptive,
                fraudulent, or illegal activity and protect the rights and property of Travogram and others; </span>
            </div>
            <div  ><span >●</span><span
            > Debug to identify and repair errors in our Services or the Site; </span></div>
            <div  ><span >●</span><span
            > Comply with our regulatory, legal and financial obligations; and </span></div>
            <div  ><span >●</span><span
            > Enforce our Terms of Use and notices; and as otherwise described to you at the
                point of
                collection </span></div>

            <div  >
                <h4 >Sharing Information with Third Parties</h4>
            </div>

            <div ><span >Travogram may
                share data with trusted partners to help perform statistical analysis, send you
                email or postal mail, provide customer support, or optimize your use of our services. All such
                third parties are prohibited from using your personal information except to provide these services
                to Travogram, and they are required to maintain the confidentiality of your information. </span>
            </div><br />
            <div  ><span >Travogram may
                disclose potentially personal information and personally-identifying information
                to its employees, contractors and affiliated organizations that (i) need to know that information in
                order to process it on Travogram’s behalf or to provide services available on the Site, and (ii)
                that have agreed to the best our knowledge not to disclose it to others. Some of those employees,
                contractors and affiliated organizations may be located outside of your home country or
                jurisdiction; by using the Site, you consent to the transfer of such information to them.
                Travogram will not rent or sell potentially personally-identifying and personally-identifying
                information to anyone. </span></div> <br />
            <div  ><span >Travogram may
                disclose your personal information, without notice, if required to do so by law or
                in the good faith belief that such action is necessary to: (a) conform to the edicts of the law or
                comply with legal process served on Travogram or the site; (b) protect and defend the rights or
                property of Travogram; and/or (c) act under exigent circumstances to protect the personal safety
                of users of Travogram, or the public. </span></div>
            <div  >
                <h4 >Tracking User Behavior</h4>
            </div>
            <div  ><span >Travogram may
                keep track of the websites and pages our users visit within Travogram, in order
                to determine what Travogram services are the most popular. This data is used to deliver
                customized content and advertising within Travogram to customers whose behavior indicates
                that they are interested in a particular subject area.</span></div>

            <div  >
                <h4 >Automatically Collected Information</h4>
            </div>
            <div  ><span >Information
                about your computer hardware and software may be automatically collected by
                Travogram. This information can include: your IP address, browser type, domain names, access
                times and referring website addresses. This information is used for the operation of the service,
                to maintain quality of the service, and to provide general statistics regarding use of the
                Travogram website. </span></div>
            <div  >
                <h4 >Use of Cookies</h4>
            </div>
            <div  ><span >The Travogram
                website may use "cookies" to help you personalize your online experience. A
                cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be
                used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you,
                and can only be read by a web server in the domain that issued the cookie to you. </span></div><br />

            <div  ><span >One of the
                primary purposes of cookies is to provide a convenience feature to save you time. The
                purpose of a cookie is to tell the Web server that you have returned to a specific page. For
                example, if you personalize Travogram pages or subdomians, or register with Travogram site or
                services, a cookie helps Travogram to recall your specific information on subsequent visits. This
                simplifies the process of recording your personal information, such as billing addresses, shipping
                addresses, and so on. When you return to the same Travogram website, the information you
                previously provided can be retrieved, so you can easily use the Travogram features that you
                customized. </span></div><br />

            <div  ><span >You have the
                ability to accept or decline cookies. Most Web browsers automatically accept
                cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you
                choose to decline cookies, you may not be able to fully experience the interactive features of the
                Travogram services or websites you visit.
            </span></div>

            <div  ><span >Third-party
                vendors, including but not limited to Google, use cookies to serve ads based on a
                user's past visits to our website.
            </span></div>

            <div  >
                <h4 >Web Beacons </h4>
            </div>
            <div  ><span >We may also use
                a technology called web beacons to collect general information about your use
                of our website and your use of special promotions or newsletters. The information we collect by
                web beacons allows us to statistically monitor the number of people who open our emails. Web
                beacons also help us to understand the behavior of our customers, members, and visitors.
            </span></div>

            <div  >
                <h4 >Geolocation
                </h4>
            </div>
            <div  ><span >When you visit
                the Site, we may use GPS (or other technology) to determine your location. We
                may use your location information directly and/or share your location with third-parties. We
                collect and share location information in order to provide you with relevant content, to analyze
                your use of our Services (as that term is defined in our Terms of Use), to improve our Services
                and to provide contextual advertising or recommendations. You may change the privacy settings
                of your device at any time in order to turn off the functionality that collects and shares location
                information and/or the functionality to tag your photos with location information. However,
                turning off location-sharing may affect certain features we offer
            </span></div>

            <div  >
                <h4 >Third Party Analytics
                </h4>
            </div>
            <div  ><span >The Site uses
                Google Analytics, to collect information about the use of our website. Google
                Analytics collects information from users such as age, gender, interests, demographics, how
                often they visit our website, what pages they visit, and what other websites they have used before
                coming to our website. We use the information we get from Google Analytics to analyze traffic,
                remarket our products and services to users, improve our marketing, advertising, and to improve
                our website. We have enabled Google Analytics advertising features such as remarketing with
                Google Analytics, Google Display Network Impression Reporting, and Google Analytics
                Demographics and Interest Reporting. Google Analytics collects only the IP address assigned to
                you on the date you visit our website, not your name or other identifying information. We do not
                combine the information collected using Google Analytics with PD. Although Google Analytics
                plants a permanent cookie on your web browser to identify you as a unique user the next me you
                visit our website, the cookie cannot be used by anyone but Google. Google also uses specific
                identifiers to help collect information about the use of our website. For more information on how
                Google collects and processes your data, visit https://www.google.com/policies/privacy/partners/
                You can prevent Google Analytics from using your information by op ng out at this link:
                https://tools.google.com/dlpage/gaoptout
            </span></div><br />
            <div  ><span >Though we
                currently use Google Analytics as described herein, we are not bound to continue to
                do so, and may at our sole discretion alter any current practice or vendor relationship.
            </span></div>

            <div  >
                <h4 >Business Transfers
                </h4>
            </div>

            <div  ><span >If Travogram,
                undergoes a Change of Control (as defined herein) your personal information may
                became an asset of such a transaction. You acknowledge that such transfers may occur, and that
                any acquirer of Travogram may continue to use your personal information as set forth in this
                policy. As used herein, Change of Control means As used herein, “Change of Control” means (i)
                a consolidation or merger of Travogram with or into any other corporation or other entity or
                person, or any other corporate reorganization, other than any such consolidation, merger or
                reorganization in which the shares of capital stock of Travogram immediately prior to such
                consolidation, merger or reorganization, continue to represent a majority of the voting power of
                the surviving entity immediately after such consolidation, merger or reorganization; (ii) any
                transaction or series of related transactions to which Travogram is a party in which in excess of
                50% of Travogram’s voting power is transferred; or (iii) the sale or transfer of all or
                substantially
                all of the Travogram’s assets, or the exclusive license of all or substantially all of Travogram’s
                material intellectual property; provided that a Change of Control shall not include any
                transaction or series of transactions principally for bona fide equity financing purposes in which
                cash is received by Travogram or any successor, indebtedness of Travogram is cancelled, or
                converted or a combination thereof.</span></div>

            <div  >
                <h4 >Links
                </h4>
            </div>

            <div  ><span >This website
                contains links to other sites. Please be aware that we are not responsible for the
                content or privacy practices of such other sites. We encourage our users to be aware when they
                leave our site and to read the privacy statements of any other site that collects personally
                identifiable information.</span>
            </div>

            <div  >
                <h4 >Security of your Personal Information
                </h4>
            </div>
            <div  ><span >Travogram
                secures your personal information from unauthorized access, use, or disclosure.
                Travogram uses the following methods for this purpose: </span></div>
            <div  ><span >-</span><span
            > &nbsp;SSL Protocol </span></div>

            <div  ><span >When personal
                information (such as name and date of birth) is transmitted to other websites, it is
                protected through the use of encryption, such as the Secure Sockets Layer (SSL) protocol. </span>
            </div>
            <div  ><span >We strive to
                take appropriate security measures to protect against unauthorized access to or
                alteration of your personal information. Unfortunately, no data transmission over the Internet or
                any wireless network can be guaranteed to be 100% secure. As a result, while we strive to
                protect your personal information, you acknowledge that: (a) there are security and privacy
                limitations inherent to the Internet which are beyond our control; and (b) security, integrity, and
                privacy of any and all information and data exchanged between you and us through this Site
                cannot be guaranteed. </span></div>
            <div  >
                <h4 >Children Under Thirteen
                </h4>
            </div>
            <div  ><span >Travogram does
                not knowingly collect personally identifiable information from children under
                the age of thirteen. If you are under the age of thirteen, you must ask your parent or guardian for
                permission to use this website. </span></div>
            <div  >
                <h4 >E-mail Communications
                </h4>
            </div>
            <div  ><span >From time to
                time, Travogram may contact you via email for the purpose of providing
                announcements, promotional offers, alerts, confirmations, surveys, and/or other general
                communication. You may opt out of such communication by contacting Travogram. </span></div>

            <div  >
                <h4 >External Data Storage Sites
                </h4>
            </div>
            <div  ><span >We may store
                your data on servers provided by third party hosting vendors with whom we have
                contracted.
            </span></div>
            <div  >
                <h4 >GDPR and Designated Countries Privacy Rights</h4>
            </div>
            <div  ><span >This Section
                only applies to Users and Customers of the Services that are located in the
                European Economic Area, United Kingdom and/or Switzerland (collectively, the “Designated
                Countries”) at the time of data collection. We may ask you to identify which country you are
                located in when you use some of the Services, or we may rely on your IP address to identify
                which country you are located in. Where we rely only on your IP address, we cannot apply the
                terms of this Section to any User or Customer that masks or otherwise obfuscates their location
                information so as not to appear located in the Designated Countries. If any terms in this Section
                conflict with other terms contained in this Policy, the terms in this Section shall apply to Users
                and Customers in the Designated Countries.</span></div> <br />
            <div  ><span >Travogram is a
                data controller with regard to any personal information collected from Customers
                or Users of its Services. A “data controller” is an entity that determines the purposes for which
                and the manner in which any personal information is processed. Any third parties that act as our
                service providers are “data processors” that handle your personal information in accordance with
                our instructions.</span></div><br />
            <div  ><span >You may object
                to our processing at any time and as permitted by applicable law if we process
                your personal information on the legal basis of consent, contract or legitimate interests. We can
                continue to process your personal information if it is necessary for the defense of legal claims, or
                for any other exceptions permitted by applicable law.</span></div><br />
            <div  ><span >If we process
                your personal information based on a contract with you or based on your consent,
                or the processing is carried out by automated means, you may request to receive your personal
                information in a structured, commonly used and machine-readable format, and to have us
                transfer your personal information directly to another “controller”, where technically feasible,
                unless exercise of this right adversely affects the rights and freedoms of others.</span></div><br />
            <div  ><span >If you believe
                we have infringed or violated your privacy rights, please contact us at
                support@travogram.com so that we can work to resolve your concerns. You also have a right to
                lodge a complaint with a competent supervisory authority situated in a Member State of your
                habitual residence, place of work, or place of alleged infringement.</span></div>

            <div  >
                <h4 >California Consumer Privacy Act of 2019 (CCPA)</h4>
            </div>

            <div  ><span >The CCPA
                provides California residents with specific rights regarding their personal
                information. This section describes your CCPA rights and explains how to exercise those
                rights.</span></div>
            <br />
            <div  ><span >You have the
                right to request that Travogram disclose certain information to you about our
                collection and use of your personal information over the past 12 months. Once we receive and
                confirm your verifiable consumer request), we will disclose to you:</span></div> <br />
            <div  ><span >-</span><span
            > &nbsp;The categories of personal information we collected about you.</span></div>
            <div  ><span >-</span><span
            > &nbsp;The categories of sources for the personal information we collected about
                you.</span></div>
            <div  ><span >-</span><span
            > &nbsp;Our business or commercial purpose for collecting or selling that personal
                information. </span></div>
            <div  ><span >-</span><span
            > &nbsp;The categories of third parties with whom we share that personal information.
            </span></div>
            <div  ><span >-</span><span
            > &nbsp;The specific pieces of personal information we collected about you (also
                called a data
                portability request). </span></div>
            <div  ><span >-</span><span
            > &nbsp;If we sold or disclosed your personal information for a business purpose, two
                separate lists
                disclosing: sales, identifying the personal information categories that each category of recipient
                purchased; and disclosures for a business purpose, identifying the personal information
                categories that each category of recipient obtained. </span></div><br />


            <div  ><span >CCPA Deletion
                Request Rights</span></div><br />
            <div  ><span >You have the
                right to request that Travogram delete any of your personal information that we
                collected from you and retained, subject to certain exceptions. Once we receive and confirm your
                verifiable consumer request, we will delete (and make reasonable efforts to direct our service
                providers to delete) your personal information from our records, unless an exception applies.</span>
            </div><br />
            <div  ><span >-</span><span
            > &nbsp;Complete the transaction for which we collected the personal information,
                provide a good or
                service that you requested, take actions reasonably anticipated within the context of our
                ongoing business relationship with you, or otherwise perform our contract with you.
                - Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal
                activity, or prosecute those responsible for such activities.
                - Debug products to identify and repair errors that impair existing intended
                functionality. </span></div>
            <div  ><span >-</span><span
            > &nbsp;Exercise free speech, ensure the right of another consumer to exercise their
                free speech
                rights, or exercise another right provided for by law.</span></div>
            <div  ><span >-</span><span
            > &nbsp;Comply with the California Electronic Communications Privacy Act (Cal. Penal
                Code §
                1546 seq.). </span></div>
            <div  ><span >-</span><span
            > &nbsp;Engage in public or peer-reviewed scientific, historical, or statistical
                research in the public
                interest that adheres to all other applicable ethics and privacy laws, when the information’s
                deletion may likely render impossible or seriously impair the research’s achievement, if you
                previously provided informed consent.</span></div>
            <div  ><span >-</span><span
            > &nbsp;Enable solely internal uses that are reasonably aligned with consumer
                expectations based on
                your relationship with us.</span></div>
            <div  ><span >-</span><span
            > &nbsp;Comply with a legal obligation.</span></div>
            <div  ><span >-</span><span
            > &nbsp;Make other internal and lawful uses of that information that are compatible
                with the context
                in which you provided it.</span></div><br />

            <div  ><span >To exercise the
                access, data portability, and deletion rights described above, please submit a
                verifiable consumer request to us by:</span></div><br /><br />

            <div  ><span >Emailing us at
                <a href="mailto:support@travogram.com" target="_blank">support@travogram.com</a></span></div><br />
        </div>
        <div >

            <div  ><span >Only you, or a
                person registered with the California Secretary of State that you authorize to act
                on your behalf, may make a verifiable consumer request related to your personal information.
                You may also make a verifiable consumer request on behalf of your minor child.</span></div><br />

            <div  ><span >You may only
                make a verifiable consumer request for access or data portability twice within a
                12-month period. The verifiable consumer request must:</span>
            </div> <br />
            <div  ><span >-</span><span
            > &nbsp;Provide sufficient information that allows us to reasonably verify you are
                the person about
                whom we collected personal information or an authorized representative.</span></div>
            <div  ><span >-</span><span
            > &nbsp;Describe your request with sufficient detail that allows us to properly
                understand, evaluate,
                and respond to it.</span></div><br />
            <div  ><span >We cannot
                respond to your request or provide you with personal information if we cannot verify
                your identity or authority to make the request and confirm the personal information relates to
                you.</span></div><br />
            <div  ><span >Making a
                verifiable consumer request does not require you to create an account with us.</span></div><br />
            <div  ><span >We will only
                use personal information provided in a verifiable consumer request to verify the
                requestor’s identity or authority to make the request.</span></div>

            <div  >
                <h4 >Changes to this Statement</h4>
            </div>
            <div  ><span >Travogram
                reserves the right to change this Privacy Policy from time to time. We will notify you
                about significant changes in the way we treat personal information by sending a notice to the
                primary email address specified in your account, by placing a prominent notice on our site,
                and/or by updating any privacy information on this page. Your continued use of the Site and/or
                Services available through this Site after such modifications will constitute your: (a)
                acknowledgment of the modified Privacy Policy; and (b) agreement to abide and be bound by
                that Policy. </span></div>
            <div  >
                <h4 >Contact Information</h4>
            </div>
            <div  ><span >Travogram
                welcomes your questions or comments regarding this Statement of Privacy. If you
                believe that Travogram has not adhered to this Statement, please contact us at: </span></div> <br />
            <div  ><span > <a
                href="mailto:support@travogram.com" target="_blank">support@travogram.com</a></span></div><br />
            <div  ><span >Effective as of
                Sept 1st 2020</span></div>











        </div>

    </div>
}

export default PrivacyPolicy;