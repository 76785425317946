const PrintDocument = () => {
    (function () {
        var originalPrintFn = window.print; // Keep it cached in this variable, just in case
        window.print = function () {
            // Trigger location change
            window.location = "com.travogram.dev:print";
        }
        window.print();
    })();
    return;
}

export default PrintDocument;