import logo from './assets/background_rotate.png';
import app_logo from './assets/travogram_logo.png';
import { APP_TYPE } from './constants/global';
import './Home.css';

const Home = () => {
  return (
    <div className="App">
      <header className="App-header">
        <div className="App-logo-background" >
          <img src={logo} className="App-logo" alt="logo" />
          <img src={app_logo} className="App-logo-icon" alt="logo" />
        </div>
        <p style={{ color: 'black' }}>
          Welcome to Travogram Website
        </p>
        <a
          className="App-link"
          href="https://www.travogram.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Travogram.com
        </a>
      </header>
    </div>
  );
}

export default Home;
