import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const goldColor = "#ffd700";
const RatingsItem = ({ rating, googleRating, googleReviews, googleReviewLink }) => {
    return <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 5 }} >
        {(rating != undefined && rating != null) && (<div style={{ flexDirection: 'row', alignItems: 'center' }} >{[...Array(rating && rating != null && rating != "" ? parseInt(rating) : 0)].map(element => <FontAwesomeIcon icon={faStar} color="black" solid size="xs" />)}</div>)}
        {googleRating != undefined && googleRating != null && <div style={{ flex: 1 }} >
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flex: 1 }} >
                <img style={{ width: 12, height: 12, marginLeft: rating && rating != null && rating != "" ? 8 : 0 }} src={require('../assets/google_icon_100.png')} />
                <span style={{ color: 'black', fontWeight: '600', marginLeft: 5, fontSize: 12 }} >{googleRating}/5</span>
                <span style={{ marginLeft: 5, fontWeight: '600', color: 'black', fontSize: 12, flex: 1, textDecorationLine: googleReviewLink && googleReviewLink != null ? 'underline' : 'none' }} numberOfLines={1} >({googleReviews})</span>
            </div>
        </div>}

    </div>
}

export default RatingsItem